.float_left {
  float: left;
  width: 100%;
}

.save_button {
  margin: 0px !important;
}

.btn_small {
  padding: 8px 10px;
  margin: 5px;
  background: $primary-color;
  color: $white;
  font-size: 10pt;
  text-align: center;
  letter-spacing: 1px;
  border: 1px solid $primary-color;
  border-radius: $border-rad5;
  -moz-border-radius: $border-rad5;
  -khtml-border-radius: $border-rad5;
  font-weight: $ft_semibold_600;
  text-transform: capitalize;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;

  &:hover {
    background: transparent;
    color: $primary-color;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
  }
}

.btn {
  padding: 12px 20px;
  margin-right: 10px;
  margin-left: 10px;
  background: $primary-color;
  color: $white;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  border: 1px solid $primary-color;
  border-radius: $border-rad5;
  -moz-border-radius: $border-rad5;
  -khtml-border-radius: $border-rad5;
  font-weight: $ft_semibold_600;
  text-decoration: none;
  text-transform: capitalize;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;

  &:hover {
    background: transparent;
    color: $primary-color;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
  }
}

.workflow-header-text {
  font-size: 1.5rem;
  margin-left: 0.313rem;
}

.toggle-switch-container {
  margin-left: 10px;
}

.active-text {
  display: inline-block;
  margin-right: 10px;
}
.workflow-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

.back-circle-icon {
  transform: rotate(90deg);
  font-size: 2.5rem !important;
  color: $primary-color;
}

.delete-btn {
  padding: 0.75rem 1.25rem;
  margin-right: 0.625rem;
  margin-left: 0.625;
  background: $white;
  color: $primary-color;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.0633rem;
  border: 0;
}

.refresh-btn {
  margin-right: 0.625rem;
  padding: 0;
  background: transparent;
  color: $primary-color;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.0633rem;
  border: 0;
}

.schedule-btn {
  padding: 0.75rem 1.25rem;
  margin-right: 0.625rem;
  margin-left: 0.625;
  background: $white;
  color: $primary-color;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.0633rem;
  border-color: $primary-color;
}

.task-node {
  height: 50px;
  width: 150px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}

.highlighted-border {
  border-color: #338bff;
}

.task-node label {
  display: block;
  color: #777;
  font-size: 12px;
}

.add-task-button {
  height: fit-content;
  color: #338bff;
  background-color: white;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  transition: border-color 0.25s;
}

.task-node-file {
  padding-top: 5px;
  color: #338bff;
}

.add-task-node {
  height: 40px;
  border: 1px solid transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  position: relative;
  cursor: pointer;
}

.add-task-node:hover {
  border-color: #646cff;
}

.add-task-node h4 {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  display: inline;
  margin: 0;
  padding: 0 5px;
}

.run-script-node {
  align-items: center;
  background-color: white;
  text-align: center;
}

.run-script-node h4 {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.upload-node {
  align-items: center;
  background-color: white;
  text-align: center;
}

.upload-node h4 {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.task-handle {
  background-color: white !important;
  width: 10px !important;
  height: 10px !important;
  border-color: #338bff !important;
  border-radius: 20px !important;
}

.task-handle:hover {
  background-color: #338bff !important;
}

.info-icon {
  color: #338bff;
  font-size: 15px !important;
  margin-left: 0 5px;
}

.task-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 7px;
  padding: 5px 0px;
}

.task-info p {
  text-align: left;
  color: #646372 !important;
  font-size: 8px !important;
  padding: 0 5px !important;
  font-weight: normal;
  line-height: normal;
}

.task-select-button {
  border-color: #b0d2ff;
  border: solid 0.5px;
  background-color: #e8f2ff;
  border-radius: 5px;
  color: #338bff;
  font-size: 12px;
  margin: 5px;
  width: 120px;
}

.task-close {
  color: black;
  cursor: pointer;
  padding: 0 5px;
}

.task-modal {
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;
  position: absolute;
  top: 50px;
}

.task-modal-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.task-modal-content {
  text-align: center;
}

.task-modal-content p {
  color: #5e6ec3;
  font-size: 10px;
  align-self: flex-start;
  padding-left: 15px;
}

.publish-modal-title {
  font-size: 16px;
  color: $black;
  font-family:"Exo 2";
  font-weight: 400;
  text-transform: capitalize;
  line-height: 28px;
  padding-bottom: 10px;
}

text-center {
  text-align: center;
}

.publish-success-message {
  margin: 50px 0;

  .check-publish {
    color:#4EBE59;
    font-size: 80px;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 20px;
    font-family:"Exo 2";
    font-weight: 400;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.publish-modal-btn {
  display: block;
  width: 50%;
  padding: 12px;
  margin: 10px 0;
  background-color: #5c59f5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family:"Exo 2";
}

.publish-modal-btn-outline {
  display: block;
  width: 50%;
  padding: 12px;
  margin: 10px 0;
  background-color: white;
  color: #5c59f5;
  border: 2px solid #5c59f5;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family:"Exo 2";
}

.publish-modal-btn:hover, .publish-modal-btn-outline:hover {
  opacity: 0.8;
}

.publish-dropdown {
  background-color: transparent;
  color: white;
  margin-left: 1rem;
}
.lds_login_main_wrapper {
  background: url("../images/thumbnails/login_bg.jpg") 50% 0 repeat-y;
  background-size: cover;
  background-position: bottom left 0;
  min-height: 100vh;
  padding-bottom: 40px;

  .lds_login_logo {
    padding: 40px;
    padding-bottom: 20px;

    h4 {
      a {
        font-weight: $ft_semibold_600;
        text-transform: uppercase;
        background: $white;
        color: $black;
        display: table;
        padding: 10px;
        letter-spacing: 1px;
        line-height: 18px;
      }
    }
  }

  .lds_login_box {
    background: $white;
    width: 430px;
    padding: 40px;
    margin: 0px auto;
    border-radius: $border-rad5;
    -moz-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;

    .lds_login_heading {
      text-align: center;

      h1 {
        font-weight: $ft_regular_400;
      }

      p {
        font-weight: $ft_semibold_600;
        font-size: $-h6;
        letter-spacing: 1px;
        padding-top: 10px;

        a {
          text-decoration: underline;
        }
      }
    }

    .lds_fp_heading {
      p {
        line-height: 21px;
        color: rgba(0, 0, 0, 0.39);
        padding-top: 20px;
      }
    }

    .lds_login_form {
      .lds_login_form_input {
        margin-top: 20px;

        input {
          width: 100%;
          height: 55px;
          border: 0;
          font-size: 18px;
          padding-left: 20px;
          border-radius: $border-rad5;
          -moz-border-radius: $border-rad5;
          -khtml-border-radius: $border-rad5;
          border: 1px solid transparent;
          -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.19);
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;

          &::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          &::-moz-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          &:-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          &:-moz-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          &:hover,
          &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-transition: $transition;
            -o-transition: $transition;
            -ms-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
            border: 1px solid $primary-color;
          }
        }

        .nice-select {
          width: 100%;
          height: 55px;
          line-height: 55px;
          border: 0;
          font-size: 18px;
          padding-left: 20px;
          color: rgba(0, 0, 0, 0.5);
          border-radius: $border-rad5;
          -moz-border-radius: $border-rad5;
          -khtml-border-radius: $border-rad5;
          -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.19);

          .list {
            width: 100%;
          }

          &:after {
            width: 9px;
            height: 9px;
            right: 15px;
            top: 46%;
            border-bottom: 2px solid rgba(0, 0, 0, 0.5);
            border-right: 2px solid rgba(0, 0, 0, 0.5);
          }
        }
      }

      .lds_login_checbox {
        margin-top: 20px;
      }

      .lds_login_checbox input[type="checkbox"] {
        display: none;
      }

      .lds_login_checbox input[type="checkbox"] + label:before {
        border: 1px solid #d8d8d8;
        content: "\00a0";
        display: inline-block;
        font: 21px/20px sans-serif;
        height: 20px;
        margin-top: 4px;
        margin-right: 10px;
        padding: 0;
        vertical-align: top;
        width: 20px;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -khtml-border-radius: 5px;
      }

      .lds_login_checbox label {
        cursor: pointer;
        font-weight: $ft_regular_400;
        font-size: 14px;
        letter-spacing: 1px;
        padding-left: 0;
        color: $black;

        a {
          color: $primary-color;
          text-decoration: none;
        }
      }

      .lds_login_checbox input[type="checkbox"]:checked + label:before {
        background: $primary-color;
        color: #fff;
        content: "\2713";
        border: 1px solid $primary-color;
      }

      .lds_login_checbox input[type="checkbox"]:checked + label:after {
        font-weight: bold;
      }

      .lds_ap_upload_img {
        text-align: center;
        padding-top: 25px;

        .avatar-edit {
          z-index: 1;
          width: 120px;
          display: inline-block;

          .btnnn {
            width: 120px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              -moz-border-radius: 100%;
              -khtml-border-radius: 100%;
            }
          }

          .avatar-preview {
            width: 120px;
            height: 120px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0px auto;
            border-radius: 100%;

            > div {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              -moz-border-radius: 100%;
              -khtml-border-radius: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }
          }

          .pr_user_img_cont_wrapper {
            input {
              display: none;
            }

            label {
              font-size: 14px;
              letter-spacing: 1px;
              color: $body-color;
              font-weight: $ft_semibold_600;
              text-align: center;
              cursor: pointer;
              margin-top: 10px;
              margin-bottom: 0;
              -webkit-transition: $transition;
              -o-transition: $transition;
              -ms-transition: $transition;
              -moz-transition: $transition;
              transition: $transition;

              &:hover {
                color: $primary-color;
                -webkit-transition: $transition;
                -o-transition: $transition;
                -ms-transition: $transition;
                -moz-transition: $transition;
                transition: $transition;
              }
            }
          }
        }
      }

      .lds_login_form_btn {
        margin-top: 20px;

        a {
          font-weight: $ft_regular_400;
          font-size: $-h6;
          letter-spacing: 1px;
          padding-left: 20px;
          color: $black;

          &:hover {
            color: $primary-color;
          }
        }
      }

      .lds_login_form_home_btn {
        a {
          font-weight: $ft_regular_400;
          font-size: $-h6;
          letter-spacing: 1px;
          padding-top: 10px;
          color: $black;
          display: block;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

/*--link index page start--*/
.link_page_body {
  background: #e7e7e7;
}

.hl_link_page_main {
  padding-bottom: 60px;

  .lds_login_logo {
    padding: 40px;
    padding-bottom: 20px;

    h4 {
      a {
        font-weight: $ft_semibold_600;
        text-transform: uppercase;
        background: $white;
        color: $black;
        display: table;
        padding: 10px;
        letter-spacing: 1px;
        line-height: 18px;
      }
    }
  }

  .hl_link_page_wrapper {
    max-width: 550px;
    background: $white;
    padding: 30px 20px;
    margin: 0 auto;

    h4 {
      font-weight: 600;
      border-bottom: 1px solid $black;
      padding-bottom: 15px;
    }

    .pr_mockup_heading {
      margin-top: 30px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin: 0 10px;
        border-bottom: 1px dashed $gray-medium;
        padding: 10px 0;

        a {
          color: $black;
          font-weight: 400;
          text-decoration: none;
          font-size: 14px;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

/*--link index page end--*/
/*--lds main menu Start--*/
.lds_cp_body {
  background: #f5f6fa;
  height: 100vh;
}

.side_lds_main_menu_wrapper {
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  background: #ffffff;
  width: 110px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  font-size: 10pt;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  #sidebar_logo {
    display: flex;
    justify-content: center;
    padding: 8px;
    img {
      height: 52px;
    }
  }
  .side_menu_items {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ul {
      flex-grow: 1;
      li,
      a {
        width: 100%;
        color: $primary-color-light;
        display: flex;
        padding: 6px 0;
        flex-direction: column;
        align-items: center;

        svg,
        img {
          width: 28px;
          height: 28px;
        }

        svg {
          border-radius: 5px;
          padding: 2px;
          transition: background-color 0.3s ease-in-out;
          transition: color 0.3s ease-in-out;
        }

        span {
          text-align: left;
          white-space: nowrap;
          line-height: 15px;
        }

        .navi_img2 {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;
        }

        &:hover {
          svg,
          img {
            background-color: $primary-color-light;
            color: white;
            box-shadow: 6px 10px 15px 0px rgba($primary-color, 0.27);
          }

          .navi_img1 {
            opacity: 0;
            visibility: hidden;
            -webkit-transition: $transition;
            -o-transition: $transition;
            -ms-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
          }

          .navi_img2 {
            opacity: 1;
            visibility: visible;
            -webkit-transition: $transition;
            -o-transition: $transition;
            -ms-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
          }
        }
      }

      a.active {
        svg,
        img {
          background-color: $primary-color-light;
          color: white;
          box-shadow: 6px 10px 15px 0px rgba($primary-color, 0.27);
        }
      }

      .menu_active {
        .navi_img1 {
          opacity: 0;
          visibility: hidden;
        }

        .navi_img2 {
          opacity: 1;
          visibility: visible;
        }
      }

      .lds_notify_list {
        a {
          position: relative;

          p {
            position: absolute;
            top: 7px;
            left: 43px;
            font-size: 14px;
            float: left;
            background: $secondary-color;
            color: $white;
            padding: 5px 6px;
            line-height: 10px;
            border-radius: $border-rad5;
            -moz-border-radius: $border-rad5;
            -khtml-border-radius: $border-rad5;
          }
        }

        ul {
          position: fixed;
          background: #f5f6fa;
          left: 250px;
          top: 30px;
          height: 91%;
          width: 370px;
          z-index: 10000;
          visibility: hidden;
          opacity: 0;
          overflow-y: auto;
          overflow-x: hidden;
          border-top-right-radius: $border-rad5;
          -moz-border-top-right-radius: $border-rad5;
          -khtml-border-top-right-radius: $border-rad5;
          border-bottom-right-radius: $border-rad5;
          -moz-border-bottom-right-radius: $border-rad5;
          -khtml-border-bottom-right-radius: $border-rad5;
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;

          &::-webkit-scrollbar-track {
            background-color: #f5f6fa;
          }

          &::-webkit-scrollbar {
            width: 2px;
            background-color: #f5f6fa;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $primary-color;
          }

          li {
            opacity: 0;
            visibility: hidden;

            .lds_notify_box {
              width: 370px;
              padding: 20px 30px;
              border-bottom: 1px solid #ebebeb;

              .lds_notify_img {
                float: left;
                width: 40px;
                padding-top: 6px;

                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .lds_notify_img_cont {
                float: left;
                width: calc(100% - 40px);
                padding-left: 20px;

                p {
                  color: $black;
                  cursor: pointer;
                  -webkit-transition: $transition;
                  -o-transition: $transition;
                  -ms-transition: $transition;
                  -moz-transition: $transition;
                  transition: $transition;

                  span {
                    font-weight: $ft_bold_700;
                  }

                  &:hover {
                    color: $primary-color;
                    -webkit-transition: $transition;
                    -o-transition: $transition;
                    -ms-transition: $transition;
                    -moz-transition: $transition;
                    transition: $transition;
                  }
                }
              }
            }
          }
        }

        &:hover {
          ul {
            visibility: visible;
            opacity: 1;
            -webkit-transition: $transition;
            -o-transition: $transition;
            -ms-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
            -moz-box-shadow: 5px 0px 9px 0 rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: 5px 0px 9px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 5px 0px 9px 0 rgba(0, 0, 0, 0.06);

            li {
              opacity: 1;
              visibility: visible;
              -webkit-transition: $transition;
              -o-transition: $transition;
              -ms-transition: $transition;
              -moz-transition: $transition;
              transition: $transition;
            }
          }
        }
      }
    }

    #logout_button {
      display: flex;
      li {
        align-self: end;
        margin: 5px;
      }
    }
  }
  .lds_bottom_menu {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }
}

@media (max-height: 765px) {
  .side_lds_main_menu_wrapper {
    font-size: 9pt;
    .side_menu_items {
      ul {
        li,
        a {
          padding: 4px;
          img,
          svg {
            height: 22px;
            width: 22px;
          }
        }
      }
    }
  }
}

/* App Details Styling*/
.app_details_header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.sliding_dev_tools_wrapper {
  height: 100%;
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .sliding_tools_header {
    display: flex;
    justify-content: space-between;
    h4 {
      margin-bottom: 10px;
    }
    svg {
      cursor: pointer;
    }
  }
  .app_dev_tools_tabs {
    border-bottom: solid 1px $gray-light;
    margin-bottom: 5px;
    display: flex;
    .tab {
      width: 60px;
      padding: 5px 0px;
      cursor: pointer;
    }
  }
  .sliding_logs {
    background-color: #f7f7f7;
    padding: 0px 8px;
    overflow: auto;
    height: 100%;
    width: 460px;
  }
}

/*--lds main menu End--*/
/*--lds project box start--*/
#lds_nbk_det {
  margin-bottom: 0px;
}

#main_header {
  padding-top: 0px;
}

.lds_right_box_wrapper {
  margin-bottom: 60px;
  margin-left: 139px;
  padding-top: 40px;
  text-align: center;

  .lds_cp_heading {
    padding-right: 30px;

    h1 {
      font-weight: $ft_regular_400;
    }

    img {
      height: 50px;
      float: left;
    }
  }

  .lds_cp_box {
    max-width: 645px;
    padding: 30px;
    margin-bottom: 40px;
    background: $white;
    display: inline-block;
    border-radius: $border-rad5;
    -moz-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
    text-align: left;

    .lds_cp_box_heading {
      h4 {
        text-transform: uppercase;
        font-weight: $ft_semibold_600;
        margin-bottom: 20px;
      }

      input {
        width: 100%;
        height: 55px;
        font-size: 18px;
        padding-left: 20px;
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        border: 1px solid #dddddd;
        -webkit-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;

        &::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &::-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:hover,
        &:focus {
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;
          border: 1px solid $primary-color;
        }
      }

      textarea {
        width: 100%;
        font-size: 18px;
        padding-left: 20px;
        padding-top: 15px;
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        border: 1px solid #dddddd;
        -webkit-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
        resize: none;

        &::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &::-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:hover,
        &:focus {
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;
          box-shadow: none;
          outline: none;
          border: 1px solid $primary-color;
        }
      }
    }

    .lds_cp_box_heading_second {
      margin-top: 50px;

      .lds_cp_tp_box {
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        overflow: hidden;
        border: 2px solid #dddddd;
        -webkit-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;

        &:hover {
          border: 2px solid $primary-color;
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;
        }

        .lds_cp_tp_img {
          img {
            width: 100%;
          }
        }

        .lds_cp_tp_img_cont {
          padding: 15px;
          background: #f5f6fa;

          h5 {
            a {
              color: $black;
              font-weight: $ft_medium_500;
            }
          }

          p {
            font-size: 14px;
            line-height: 19px;
            padding-top: 5px;
            color: #797979;
          }
        }
      }

      .nice-select {
        width: 100%;
        height: 55px;
        line-height: 55px;
        border: 0;
        font-size: 18px;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.5);
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        border: 1px solid #dddddd;

        .list {
          width: 100%;
        }

        &:after {
          width: 9px;
          height: 9px;
          right: 15px;
          top: 46%;
          border-bottom: 2px solid rgba(0, 0, 0, 0.5);
          border-right: 2px solid rgba(0, 0, 0, 0.5);
        }
      }
    }

    .lds_cp_box_heading_third {
      margin-top: 30px;
    }
  }
}

.lds_right_box_wrapper_template {
  margin-bottom: 60px;
  text-align: center;

  .lds_cp_heading {
    padding-right: 30px;

    h1 {
      font-weight: $ft_regular_400;
    }

    img {
      height: 50px;
      float: left;
    }
  }

  .lds_cp_box {
    max-width: 645px;
    padding: 30px;
    margin-bottom: 40px;
    background: $white;
    display: inline-block;
    border-radius: $border-rad5;
    -moz-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
    text-align: left;

    .lds_cp_box_heading {
      h4 {
        text-transform: uppercase;
        font-weight: $ft_semibold_600;
        margin-bottom: 20px;
      }

      input {
        width: 100%;
        height: 55px;
        font-size: 18px;
        padding-left: 20px;
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        border: 1px solid #dddddd;
        -webkit-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;

        &::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &::-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:hover,
        &:focus {
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;
          border: 1px solid $primary-color;
        }
      }

      textarea {
        width: 100%;
        font-size: 18px;
        padding-left: 20px;
        padding-top: 15px;
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        border: 1px solid #dddddd;
        -webkit-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
        resize: none;

        &::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &::-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:-moz-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        &:hover,
        &:focus {
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;
          box-shadow: none;
          outline: none;
          border: 1px solid $primary-color;
        }
      }
    }

    .lds_cp_box_heading_second {
      margin-top: 50px;

      .lds_cp_tp_box {
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        overflow: hidden;
        border: 2px solid #dddddd;
        -webkit-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;

        &:hover {
          border: 2px solid $primary-color;
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;
        }

        .lds_cp_tp_img {
          img {
            width: 100%;
          }
        }

        .lds_cp_tp_img_cont {
          padding: 15px;
          background: #f5f6fa;

          h5 {
            a {
              color: $black;
              font-weight: $ft_medium_500;
            }
          }

          p {
            font-size: 14px;
            line-height: 19px;
            padding-top: 5px;
            color: #797979;
          }
        }
      }

      .nice-select {
        width: 100%;
        height: 55px;
        line-height: 55px;
        border: 0;
        font-size: 18px;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.5);
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        border: 1px solid #dddddd;

        .list {
          width: 100%;
        }

        &:after {
          width: 9px;
          height: 9px;
          right: 15px;
          top: 46%;
          border-bottom: 2px solid rgba(0, 0, 0, 0.5);
          border-right: 2px solid rgba(0, 0, 0, 0.5);
        }
      }
    }

    .lds_cp_box_heading_third {
      margin-top: 30px;
    }
  }
}

/*--lds project box End--*/
/*--lds project overview start--*/
.lds_right_po_box_wrapper {
  text-align: left;
  margin: 0px 140px;

  .lcol_right_po_box_wrapper {
    text-align: left;
    margin-left: -110px;
    margin-right: -66px;
  }

  .lds_po_heading {
    padding-top: 5px;

    h1 {
      font-weight: $ft_regular_400;
    }
  }

  .lds_section_header {
    margin-top: 2rem;
  }

  .lds_empty_state {
    width: 100%;
    height: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lds_po_cp_btn {
    button,
    a {
      float: right;
    }
  }

  .lds_po_tabs_main_box {
    margin-top: 60px;
    padding: 0px;

    .lds_po_tabs {
      margin-left: 15px;

      ul {
        li {
          margin-right: 25px;

          a {
            font-size: 14px;
            color: $black;
            font-weight: $ft_regular_400;
            padding: 0;

            text-transform: none;

            &.active {
              background: transparent;
              color: $primary-color;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .lds_po_search {
      float: right;

      a {
        font-size: 14px;
        color: $black;
        font-weight: $ft_regular_400;

        i {
          position: relative;
          top: 4px;
          padding-left: 5px;
        }
      }

      #search_button {
        margin-top: 7px;
        font-size: 14px;
      }

      .lds_search_box {
        position: absolute;
        right: 15px;
        top: 118%;
        display: inline-block;
        border-radius: 0px;
        -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
        -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
        -o-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
        -ms-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
        background-color: #ffffff;
        display: none;
        z-index: 1000;
        overflow: hidden;
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;

        input {
          float: left;
          width: calc(100% - 55px);
          padding: 0px 10px;
          height: 55px;
          border: 1px solid transparent;
          border-right: 0px;
        }

        button {
          width: 55px;
          height: 55px;
          border: 1px solid transparent;
          border-left: none;
          background-color: $primary-color;
          color: $white;
          text-align: center;
          padding: 0px;
          -webkit-transition: all 0.5s;
          -o-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -moz-transition: all 0.5s;
          transition: all 0.5s;
        }
      }
    }

    .filter-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      float: right;
      margin-right: 10px;
      margin-bottom: 5px;

      .filter-dropdown-button {
        button {
          background-color: rgba(0, 0, 0, 0);
          color: $primary-color;
          padding: 5px;
          border: none;
          color: rgb(0, 0, 0);
          margin: 0px 3px;
          box-shadow: none;

          &:hover {
            background-color: rgba(191, 191, 191, 0.559);
          }
        }
      }

      svg {
        cursor: pointer;
        border-radius: 100px;

        &:hover {
          background-color: rgba(191, 191, 191, 0.559);
        }
      }
    }

    .lds_po_filter_dropdown {
      float: right;
      margin-right: 15px;
      font-size: 14px;
    }

    .lds_po_filter_dropdown .nice-select {
      height: 30px;
      width: auto;
      border: 0;
      border-radius: 0;
      background: transparent;
      line-height: 30px;
      padding: 0;
      padding-right: 28px;

      &:after {
        width: 7px;
        height: 7px;
        border-bottom: 1px solid $black;
        border-right: 1px solid $black;
      }
    }

    .lds_po_filter_dropdown li a {
      float: left;
      width: 100%;
      padding: 10px 15px;
      font-size: 14px;
      color: $black;
      font-weight: $ft_regular_400;
      border-bottom: 1px solid #ededed;
      -webkit-transition: $transition;
      -o-transition: $transition;
      -ms-transition: $transition;
      -moz-transition: $transition;
      transition: $transition;
    }

    .lds_po_filter_dropdown .nice-select.open .current {
      color: $primary-color;
    }

    .lds_po_filter_dropdown .nice-select.open:after {
      -webkit-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
      border-bottom: 1px solid $primary-color;
      border-right: 1px solid $primary-color;
    }

    .lds_po_filter_dropdown .current {
      color: $black;
      font-weight: $ft_regular_400;
      font-size: 14px;
    }

    .lds_po_filter_dropdown .list {
      width: 210px;
      left: auto;
      right: 0;
      box-shadow: none;
      -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
      -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
      -o-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
      -ms-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);

      li {
        a {
          i {
            &:before {
              font-size: 14px;
              color: $black;
              padding-right: 5px;
              -webkit-transition: $transition;
              -o-transition: $transition;
              -ms-transition: $transition;
              -moz-transition: $transition;
              transition: $transition;
            }
          }

          &:hover {
            color: $primary-color;

            i {
              &:before {
                color: $primary-color;
                -webkit-transition: $transition;
                -o-transition: $transition;
                -ms-transition: $transition;
                -moz-transition: $transition;
                transition: $transition;
              }
            }
          }
        }
      }
    }

    .lds_po_filter_dropdown i:before {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}

.tabu__Sper {
  background: #fff;
  float: left;
  width: 100%;
  margin: 30px 10px 10px 10px;
  border-radius: $border-rad5;
  -moz-border-radius: $border-rad5;
  -khtml-border-radius: $border-rad5;

  .radio_button {
    margin-top: 60px;
  }
}

.sper__Row {
  float: left;
  width: 100%;
  border-bottom: solid 2px #f1f1f1;
  padding: 25px;
}

.sper__left {
  float: left;
  width: 78px;
  object-fit: contain;
}

.sper__img {
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  justify-content: center;

  img {
    height: 78px;
  }
}

.sper__right {
  float: right;
  width: calc(100% - 100px);
  text-align: left;
}

.inn__L1 {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.inn__L1 {
  h4 {
    a {
      color: $black;
      font-weight: $ft_semibold_600;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.inn__L1 p {
  color: #525252;
  font-size: 15px;
  margin-top: 3px;
}

.inn__bottom {
  float: left;
  width: 100%;
  margin-top: 5px;

  img {
    height: 14px;
  }
}

.inn__avtar {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 100px;
}

.inn__avtar img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
  object-position: center;
}

.inn__anchor {
  float: left;
}

.inn__bottom a {
  color: #525252;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.inn__bottom a img {
  display: inline-block;
  margin-right: 10px;
  margin-top: -2px;
}

.inn__bottom a:hover,
.inn__bottom a:focus {
  color: $primary-color;
}

.inn__R1 {
  display: inline-block;
  text-align: right;
  width: 100%;
  vertical-align: middle;
}

#vertalign {
  vertical-align: middle;
}

.ml_icn {
  display: inline-block;
  vertical-align: middle;
  padding-top: 15px;
  width: 100%;
  text-align: right;
}

.ml_icn span {
  display: inline-block;
  text-align: center;
  margin: 0 15px;
}

.ml_icn span img {
  display: block;
  margin-bottom: 3px;
}

.pr__bottom {
  float: left;
  width: 100%;
  margin: 50px 0;
}

.pr__BX_1,
.pr__BX_2 {
  background: url("../images/thumbnails/player_bgh.png") left top;
  min-height: 314px;
  background-color: #47b49f;
  color: #fff;
  background-repeat: no-repeat;
  border-radius: 6px;
  float: left;
  padding: 40px 40px 40px 255px;
  width: 100%;
  text-align: left;
}

.pr__BX_2 {
  background: url("../images/thumbnails/game_bgh.png") left top;
  background-color: #476bf7;
  background-repeat: no-repeat;
}

.pr__BX_1 h3,
.pr__BX_2 h3 {
  font-size: 36px;
  text-align: left;
  font-weight: 400;
  color: #fff;
}

.pr__BX_1 span,
.pr__BX_2 span {
  font-size: 18px;
  float: left;
  width: 100%;
  margin: 15px 0;
}

.r__more {
  float: left;
  border: solid 1px #fff;
  padding: 6px 15px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  letter-spacing: 1px;
}

.r__more:hover,
.r__more:focus {
  color: #202125;
  border-color: #202125;
}

.pr__bottom_Top {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 80px;
  margin-bottom: 30px;
}

.pr__bottom_Top_market {
  float: left;
  width: 100%;
  text-align: left;
}

.pr__bottom_Top h4 {
  float: left;
}

.pr__bottom_Top .see__an {
  float: left;
  text-transform: uppercase;
  margin-left: 15px;
  font-size: 16px;
  margin-top: -2px;
  font-weight: 600;
}

.pr__bottom_Top .see__an:hover,
.pr__bottom_Top .see__an:focus {
  color: #202125;
}

.pr__bottom_Top .see__an i {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
  display: inline-block;
  margin-top: -2px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.lds_pss_top_right .align_center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lds_pss_top_right .theme_btn {
  background: #902dd2;
  outline: none;
  border: 1px solid #902dd2;
  transition: all 0.5s ease 0s;
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 10px;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  outline: none;
}

.lds_pss_top_right .theme_btn:hover {
  transition: all 0.5s ease 0s;
  color: #902dd2;
  background: $white;
}

.lds_pss_top_right .theme_btn:focus {
  box-shadow: none;
}

.lds_pss_top_right .h4_title {
  margin: 0;
  font-size: 34px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: $black;
}

.lds_pss_top_right .modal-content {
  width: 100%;
  min-width: 450px;
  max-width: 450px;
  display: block;
  border: none;
  box-shadow: 13px 15px 26px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 0 auto;
}

.lds_pss_top_right .modal-body {
  text-align: center;
  padding: 70px 20px;
}

.lds_pss_top_right .modal_btms {
  text-align: center;
  margin-top: 30px;
}

.lds_pss_top_right .theme_bg_btm {
  background: #902dd2;
  outline: none;
  border: 1px solid #902dd2;
  transition: all 0.5s ease 0s;
  color: #ffffff;
}

.lds_pss_top_right .theme_bg_btm:hover {
  background: #ffffff;
  transition: all 0.5s ease 0s;
  color: #902dd2;
}

.lds_pss_top_right .theme_outline_btn {
  background: #ffffff;
  outline: none;
  border: 1px solid #000000;
  color: #000000;
}

.lds_pss_top_right .theme_outline_btn:hover {
  background: #902dd2;
  transition: all 0.5s ease 0s;
  color: #ffffff;
  border: 1px solid #902dd2;
}

.lds_pss_top_right .custom_btn {
  padding: 12px 20px;
  text-align: center;
  letter-spacing: 1px;
  margin-right: 15px;
  border-radius: $border-rad5;
  -moz-border-radius: $border-rad5;
  -khtml-border-radius: $border-rad5;
  display: inline-block;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: $ft_semibold_600;
}

.lds_pss_top_right .custom_btn:last-child {
  margin-right: 0px;
}

.lds_pd_heading_btn {
  padding: 30px;
  border-bottom: solid 2px #f1f1f1;
  float: left;
  width: 100%;

  h4 {
    font-weight: $ft_semibold_600;
    color: $primary-color;
    float: left;
    padding-top: 15px;
  }

  a {
    float: right;
    border: 1px solid $black;
    color: $black;
    background: transparent;

    &:hover {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: $white;
    }
  }
}

.lds_pd_last_tabu__Sper {
  margin-bottom: 60px;
}

.lds_pd_top {
  padding-top: 15px;
}

.lds_pd_right_client {
  padding-bottom: 15px;

  h5 {
    font-size: 14px;
    color: #959595;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    color: $black;
    font-weight: $ft_semibold_600;
    padding-top: 3px;
  }
}

.lds_pd_client_box {
  margin-top: 15px;

  .lds_pd_client_img {
    float: left;
    width: 50px;

    img {
      width: 100%;
    }
  }

  .lds_pd_client_img_cont {
    float: left;
    width: calc(100% - 50px);
    padding-left: 10px;
    padding-top: 10px;

    a {
      font-size: 16px;
      text-transform: capitalize;
      color: $black;
      font-weight: $ft_medium_500;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.lds_pd_right_client_second {
  padding-top: 15px;
  border-top: solid 2px #f1f1f1;
}

/*--lds project overview End--*/
/*--lds Acoount Profile Start--*/
.lds_ap_heading {
  position: relative;
  top: -160px;
}

.advanced_settings_btn {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: rgb(51, 51, 51);

  &:hover {
    border-color: $primary-color;
  }
}

.lds_ap_box {
  .lds_login_form_input {
    margin-top: 20px;

    input {
      width: 100%;
      height: 55px;
      border: 0;
      font-size: 18px;
      padding-left: 20px;
      border-radius: $border-rad5;
      -moz-border-radius: $border-rad5;
      -khtml-border-radius: $border-rad5;
      border: 1px solid #dddddd;
      -webkit-transition: $transition;
      -o-transition: $transition;
      -ms-transition: $transition;
      -moz-transition: $transition;
      transition: $transition;

      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &::-moz-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &:-moz-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &:hover,
      &:focus {
        -webkit-transition: $transition;
        -o-transition: $transition;
        -ms-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
        border: 1px solid $primary-color;
      }
    }

    .nice-select {
      width: 100%;
      height: 55px;
      line-height: 55px;
      border: 0;
      font-size: 18px;
      padding-left: 20px;
      color: rgba(0, 0, 0, 0.5);
      border-radius: $border-rad5;
      -moz-border-radius: $border-rad5;
      -khtml-border-radius: $border-rad5;
      border: 1px solid #dddddd;

      .list {
        width: 100%;
      }

      &:after {
        width: 9px;
        height: 9px;
        right: 15px;
        top: 46%;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-right: 2px solid rgba(0, 0, 0, 0.5);
      }
    }
  }

  .lds_login_checbox {
    margin-top: 20px;
  }

  .lds_login_checbox input[type="checkbox"] {
    display: none;
  }

  .lds_login_checbox input[type="checkbox"] + label:before {
    border: 1px solid #d8d8d8;
    content: "\00a0";
    display: inline-block;
    font: 21px/20px sans-serif;
    height: 20px;
    margin-top: 4px;
    margin-right: 10px;
    padding: 0;
    vertical-align: top;
    width: 20px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
  }

  .lds_login_checbox label {
    cursor: pointer;
    font-weight: $ft_regular_400;
    font-size: 14px;
    letter-spacing: 1px;
    padding-left: 0;
    color: $black;

    a {
      color: $primary-color;
      text-decoration: none;
    }
  }

  .lds_login_checbox input[type="checkbox"]:checked + label:before {
    background: $primary-color;
    color: #fff;
    content: "\2713";
    border: 1px solid $primary-color;
  }

  .lds_login_checbox input[type="checkbox"]:checked + label:after {
    font-weight: bold;
  }

  .lds_ap_upload_img {
    text-align: center;
    padding-top: 25px;

    .avatar-edit {
      z-index: 1;
      width: 120px;
      display: inline-block;

      .btnnn {
        width: 120px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          -moz-border-radius: 100%;
          -khtml-border-radius: 100%;
        }
      }

      .avatar-preview {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0px auto;
        border-radius: 100%;

        > div {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          -moz-border-radius: 100%;
          -khtml-border-radius: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }

      .pr_user_img_cont_wrapper {
        input {
          display: none;
        }

        label {
          font-size: 14px;
          letter-spacing: 1px;
          color: $body-color;
          font-weight: $ft_semibold_600;
          text-align: center;
          cursor: pointer;
          margin-top: 10px;
          margin-bottom: 0;
          -webkit-transition: $transition;
          -o-transition: $transition;
          -ms-transition: $transition;
          -moz-transition: $transition;
          transition: $transition;

          &:hover {
            color: $primary-color;
            -webkit-transition: $transition;
            -o-transition: $transition;
            -ms-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
          }
        }
      }
    }
  }

  .lds_login_form_btn {
    margin-top: 20px;

    a {
      font-weight: $ft_regular_400;
      font-size: $-h6;
      letter-spacing: 1px;
      padding-left: 20px;
      color: $black;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .lds_login_form_home_btn {
    a {
      font-weight: $ft_regular_400;
      font-size: $-h6;
      letter-spacing: 1px;
      padding-top: 10px;
      color: $black;
      display: block;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

/*--lds Workflow Start--*/
.lds_workflow_right_top_box_wrapper {
  padding-left: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: linear-gradient(to bottom, transparent, #ffffffc2);
  background-size: cover;
  background-position: center 0;
}

.lds_workflow_bottom_box_wrapper {
  padding-left: 0px;
  background-color: #f2f4ff;
}

.workflow-list-link {
  a {
    color: #1871c1;
    font-size: 1rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      -webkit-transition: $transition;
      -o-transition: $transition;
      -ms-transition: $transition;
      -moz-transition: $transition;
      transition: $transition;
    }
  }
}

/*--lds Acoount Profile End--*/
/*--lds Project Start--*/
.lds_project_right_top_box_wrapper {
  padding-left: 139px;
  padding-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 110px;
  height: 175px;
  background: linear-gradient(to bottom, #ffffff00, #ffffff);

  .lds_pss_top_left {
    a {
      font-size: 22px;
      color: $white;
      font-weight: $ft_medium_500;
      svg {
        color: $primary-color;
      }
      span {
        margin-left: 3px;
      }
    }
    .dataset_info_wrapper {
      margin-top: 15px;
      color: #525252;
    }
    #dataset_desc {
      margin-top: 15px;
      color: #525252;
    }
    h1 {
      font-weight: $ft_regular_400;
      color: $white;
      padding-top: 30px;
    }

    p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.64);
      padding-top: 10px;
    }
  }
  .lds_pss_top_right {
    padding-top: 43px;

    ul {
      float: right;

      li {
        float: left;
        margin-left: 15px;

        &:first-child {
          margin-left: 0;

          a {
            background: transparent;
            border: 1px solid $white;
            color: $white;

            &:hover {
              background: $white;
              color: $primary-color;
            }
          }
        }

        &:last-child {
          a {
            background: $white;
            border: 1px solid $white;
            color: $primary-color;

            &:hover {
              background: transparent;
              color: $white;
            }
          }
        }
      }
    }
  }
}

.details-container {
  padding-top: 10px;
}

.toolbar_icn_disabled {
  color: #8f8f8f;
  pointer-events: none;
}

@media (max-width: 991px) {
  .project_details_wrapper.expanded {
    margin-left: 0px !important;
    margin-right: 0px !important;

  }
  .project_details_wrapper {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
}

.project_details_wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 210px;
  margin-right: 100px;
  height: 90vh;
  transition: all 0.3s ease-in-out;
  &.expanded {
    margin-left: 110px;
    margin-right: 0px;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    .projects_header {
      transition: all 0.3s ease-in-out;
    }
  }

  svg {
    cursor: pointer;
  }

  .projects_header {
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 70px;
    svg {
      color: $primary-color;
    }

    .projects_header_right {
      display: flex;
      align-items: center;
      margin-right: 25px;
      white-space: nowrap;
    }
  }

  .code_area_wrapper {
    max-height: calc(100% - 70px);
    flex-grow: 1;
    display: flex;
    box-shadow: 15px 15px 60px 0px rgb(197, 197, 219, 0.25);
    svg:hover {
      filter: drop-shadow(0 0 10px $gray-medium);
    }
    .no_drop_shadow {
      filter: none !important;
    }

    .ace_wrapper {
      flex: 1;
    }

    .editor_toolbar {
      width: 100%;
      height: 35px;
      background-color: #e9ecf6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .toolbar_left {
        display: flex;

        .tooltip {
          font-size: 12px;
        }
      }
      .toolbar_icn {
        cursor: pointer;
        color: $primary-color;
        font-size: 28px;
        margin-left: 10px;
        &_disabled {
          @extend .toolbar_icn;
          color: #8f8f8f;
          pointer-events: none;
        }
      }
      select {
        margin-right: 10px;
      }
      .options_select {
        cursor: pointer;
      }
    }

    .resize-wrapper {
      display: flex;
    }

    .border_handle_right {
      cursor: col-resize;
      width: 5px;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }

    .border_handle_left {
      cursor: col-resize;
      width: 5px;
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
  h5 {
    margin: 10px;
    font-family: inherit;
    color: inherit;
  }

  .code_and_tools_wrapper {
    display: flex;
    flex-grow: 1;
  }

  .ace_editor_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    font-family: "Lato", sans-serif;
  }

  .ace_scrollbar {
    display: none;
  }

  .area_header {
    background-color: $primary-blue;
    width: 100%;
    height: 45px;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
      margin: 10px;
      cursor: pointer;
    }
  }

  .file_navigator_wrapper {
    display: flex;
    flex-direction: column;
    background-color: #e9ecf6;
    color: $primary-blue;
    border-right: solid 1px #bfc8e8;

    .nav_assets_wrapper {
      flex-grow: 1;
      max-height: calc(100% - 80px);
    }

    .project_nav_area {
      max-height: 33%;
      min-height: 20%;
      overflow-y: auto;

      .nav_toolbar {
        padding: 0px 10px;
        display: flex;

        .add_file {
          text-align: right;
        }
      }

      h3 {
        color: $primary-blue;
        align-self: center;
      }
    }

    .project_datasets {
      max-height: 33%;
      min-height: 20%;
      overflow-y: auto;
      overflow-x: visible;
      padding-right: 10px;
      border-top: solid 1px #bfc8e8;
      display: flex;
      flex-direction: column;
    }
  }

  .project_workflows {
    max-height: 33%;
    min-height: 20%;
    border-top: solid 1px #bfc8e8;
    padding: 1px 10px;
    overflow-y: auto;

    .linked_workflow_title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      svg {
        margin-left: auto;
      }
    }
    .workflow_item {
      display: flex;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .material-symbols-outlined, svg {
        color: $gray-medium;
        cursor: auto;
        &:hover {
          filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0));
          cursor: default;
        }
      }
    }
    .no_workflows {
      color: $gray-medium;
      font-size: 12px;
    }
    .project_workflow_dropdown {
      margin-left: auto;
      svg {
        cursor: pointer;
      }
    }
  }

  .project_files {
    #dataset_filename {
      overflow-y: visible;
      cursor: pointer;
    }
    .project_dataset_folder {
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      color: $gray-dark;
    }

    .project_dataset_file {
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      color: $gray-medium;
    }

    .project_file {
      padding: 1px 10px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .project_file_left {
        display: flex;
        align-items: center;
        overflow: hidden;
        .project_file_name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        svg {
          color: $gray-light;
          &:hover {
            filter: none;
          }
        }
      }
      .project_file_right {
        display: flex;
        align-items: center;
      }
    }

    .project_folder {
      display: flex;
      justify-content: space-between;
    }

    div {
      font-size: 12px;
      white-space: nowrap;
    }
    .active {
      background-color: #fbfcff;
    }
    .chevron_closed {
      transform: rotate(90deg);
    }
  }

  .dev_windows_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-left: 1px solid #bfc8e8;
    border-radius: 0px 10px 10px 0px;
    .dev_tool_header {
      background-color: $primary-blue;
      width: 100%;
      height: 45px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        margin: 10px;
        cursor: pointer;
      }
    }

    .terminal_wrapper {
      display: flex;
      flex-direction: column;
      .terminal_ref {
        height: calc(100% - 45px);
        overflow-y: auto;
      }
    }

    .env_vars_table {
      height: calc(100% - 45px);
      overflow-y: auto;
      .env_var_row {
        display: flex;
        justify-content: space-between;
        font-size: 0.8rem;
        color: $gray-medium;
        margin-top: 10px;
        padding: 0px 15px;
        width: 100%;

        input {
          border: 1px solid #e2e2e2;
          border-radius: 4px;
          height: 25px;
          width: -webkit-fill-available;
          color: $gray-medium;
        }

        .env_var_info {
          display: flex;
          width: 80%;
          .secret_key {
            width: 50%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-right: 40px;
          }
          .secret_value {
            width: 50%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-right: 15px;
          }
          .secret_value_hidden {
            @extend .secret_value;
            -webkit-text-security: disc;
          }
        }
        .env_var_options {
          display: flex;
          svg {
            margin-right: 7px;
          }
        }
      }
    }
  }

  .close_dev_icn {
    float: right;
  }

  iframe {
    width: 100%;
    min-height: 400px;
  }
}

.container_logs_text {
  white-space: pre-wrap;
  overflow: auto;
  padding-left:10px;
  padding-right:10px;
  color: #272833;

  .logs {
    display: flex;
    flex-direction: column;
    position: relative;

    .summary_text_box {
      display: flex;
      flex-direction: column;
      position: fixed;
      bottom: 60px;
      right: 30px;
      width: 350px;
      border: 0;
      border-radius: 8px;
      z-index: 999;
      padding: 20px;
      background: #F9F9F9;
      align-self: center;
      font-size: 12px;
      font-family: "Lato", sans-serif;;
      line-height: 16px;
      color: #646372;

      .summary_header_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .summary_header {
          color: #4C5BAB;
          font-size: 14px;
          font-family: "Lato", sans-serif;
          font-weight: 700;
        }
      }
    }

    .workflows_text_box {
      bottom: 20px;
      right: 5px;
    }

    .summary_button {
      z-index: 999;
      position: fixed;
      align-self: flex-end;
      right: 60px;
      bottom: 13px;
      border: 0;
      border-radius: 8px;
      background: #e9ecf6;
      font-size: 16px;
      font-family: "Lato", sans-serif;
      color: #4C5BAB;
      padding: 5px;


      .summary_button_container {
        display: flex;
        flex-direction: row;

        .summary_button_text {
          margin-left: 10px;
        }
      }
    }

    .workflows_summary_button {
      z-index: 999;
      align-self: flex-end;
      border: 0;
      position: relative;
      border-radius: 8px;
      background: #e9ecf6;
      font-size: 16px;
      font-family: "Lato", sans-serif;
      color: #4C5BAB;
      padding: 5px;


      .summary_button_container {
        display: flex;
        flex-direction: row;

        .summary_button_text {
          margin-left: 10px;
        }
      }
    }
  }
}

.show_summary {
  background: #7B7F88;
}

.container_logs_wrapper {
  overflow: hidden;
  .container_logs_text {
    height: calc(100% - 45px);
  }
}

.tab-content {
  .container {
    padding-right: 0px;
  }
}

.workflow_menu_container {
  display: flex;

  p {
    margin-left: 9px;
  }
}

.project_row {
  overflow: hidden;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  margin: 20px 0px;
  border-radius: $border-rad5;
  min-height: 125px;
  border-left: solid 12px;
  color: #525252;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .project_title {
      color: $primary-color;
      transition: all 0.3s ease-in;
    }
  }
  .project_title {
    color: $black;
    font-weight: $ft_bold_700;
    transition: all 0.3s ease-in;
    font-size: 1.1rem;
  }

  h4,
  button {
    margin-bottom: 20px;
  }
}

.project_text_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.project_text_right {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: flex-end;
}

.project_info {
  align-self: flex-end;
  display: flex;

  #edited_from {
    width: 192px;
  }

  .project_info_item {
    display: flex;
    font-size: 14px;
    margin-right: 10px;
    align-items: center;
    width: 150px;
    svg {
      align-self: center;
      background-color: #ffffff;
    }
    .info_icns {
      display: flex;
      width: 30px;
      align-items: center;
      .info_icn_wrapper {
        display: flex;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.348);
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.lds_signup_inner_label {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.secondary_btn {
  background: #ffffff;
  outline: none;
  border: 1px solid #902dd2;
  color: #902dd2;
  padding: 5px;
  font-size: 13px;
}

.secondary_btn:hover {
  transition: all 0.5s ease 0s;
  color: #ffffff;
  background: #902dd2;
}

.lds_project_bottom_box_wrapper {
  padding-left: 139px;

  .lds_project_over_box {
    padding: 30px;
    background: $white;
    border-radius: $border-rad5;
    -moz-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
    position: relative;

    h4 {
      font-weight: $ft_medium_500;
    }

    p {
      padding-top: 5px;
      line-height: 20px;

      span {
        font-size: 14px;
      }
    }

    h5 {
      font-size: 14px;
      color: #959595;
      padding-top: 20px;
      text-transform: uppercase;
    }
  }
}

/*--lds Project End--*/
/*--lds Home Start--*/
.hero__Portion {
  float: left;
  width: 100%;
}

.hero__Pol {
  position: relative;
}

.bg__pic {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  z-index: -1;
}

.bg__home {
  height: 120%;
}

.bg__pic img {
  width: 100%;
  height: 130%;
  object-fit: cover;
  object-position: center;
}

.home__Title {
  display: block;
  font-size: 54px;
  letter-spacing: 0.3px;
  color: #ffffff;
  font-weight: 500;
  line-height: 65px;
  -webkit-text-stroke: 1px #000;
  padding-top: 105px;
}

.home__Title span {
  display: block;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 28px;
  -webkit-text-stroke: 1px #000;
}

.home_dash {
  margin-top: 100px;
}

.home__Intro {
  float: left;
  margin-bottom: 50px;
}

.home_card {
  float: left;
  width: 100%;
  height: 250px;
  border: none;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  overflow: hidden;

  figure {
    margin: 0px 0 0 0rem;
  }
}

.home-apps-wrapper {
  background-color: #f5f6fa;
  padding: 20px;
  border-radius: 5px;

  .nav-link-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-link {
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0);
      color: $primary-color;
      padding: 5px;
      border: none;
      color: rgb(0, 0, 0);
      margin: 0px 3px;
      box-shadow: none;
      text-transform: none;

      &:hover {
        background-color: rgba(191, 191, 191, 0.559);
      }
    }
  }

  .no-pinned-content {
    width: 100%;
    text-align: center;
    color: #bababa;
    font-size: large;
    padding: 150px;
  }

  .recently-pinned-wrapper {
    display: flex;
    align-items: center;

    img {
      margin: 5px;
    }

    h5 {
      color: #525252;
    }
  }

  .corner-icn {
    height: 25px;
  }
}

.gp_header {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.code_title {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000000;
}

.optionsIcon {
  z-index: 1;
  cursor: pointer;
  height: 25px;
}

.flighting_config {
  p {
    padding-left: 10px;
  }
}

.delete {
  p {
    padding-left: 10px;
  }
}

.delete_icn {
  margin: 7.5px;
}

.delete_icn_dataset {
  margin-top: -10px;
}

.disabled-dataset-icon {
  opacity: 0.3;
  pointer-events: none;
}

.show__tool_left {
  width: 26px;
  height: 26px;
  float: left;
}

.ludis_app_logo {
  margin-right: 8px;
}

.external_app_icon {
  color: #305ed2;
  margin-right: 8px;
}

.show__tool {
  width: 26px;
  height: 26px;
  float: right;
  position: relative;

  .show_pin_app {
    cursor: pointer;
    color: gold;
    left: -56px;
  }

  .hide_pin_app {
    cursor: pointer;
    color: lightgray;
    left: -56px;
  }

  .show_pin_rep {
    left: -71px;
  }

  span {
    float: left;
    width: 135px;
    height: 35px;
    background: $black;
    color: $white;
    position: absolute;
    left: -56px;
    top: -35px;
    text-align: center;
    line-height: 34px;
    border-radius: $border-rad5;
    -moz-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;

    &:after {
      content: "";
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid $black;
      position: absolute;
      bottom: -9px;
      left: 60px;
    }
  }

  &:hover {
    span {
      opacity: 1;
      visibility: visible;
      top: -40px;
      -webkit-transition: $transition;
      -o-transition: $transition;
      -ms-transition: $transition;
      -moz-transition: $transition;
      transition: $transition;
    }
  }
}

.show__tool img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app_menu {
  width: 26px;
  height: 26px;
  float: right;
  position: relative;

  svg {
    color: #cccccc;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.entity_menu_item {
  display: flex;
  position: relative;

  p {
    padding-left: 5px;
  }

  img {
    display: block;
  }
}

.share_entity_modal {
  display: flex;
  flex-direction: column;
  z-index: 1300;
  margin-top: 25px;

  .share_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.25rem 1rem 2.25rem;

    .select_org_users_header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .MuiIconButton-root {
        padding-left: 0;
      }
    }

    .header_text {
      font-size: 24px;
      font-weight: 700;
      font-family: "Exo 2", sans-serif;
    }
  }
}

.share_entity_modal_body {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  padding-top: 0;
  padding-bottom: 0;

  .select_users {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .select_org_users_button_container {
      padding-bottom: 1.75rem;
      border-bottom: 1px solid #cdcdcd;

      .select_org_users_button {
        font-size: 14px;
        width: 50%;
        padding: 0.5rem 0.75rem 0.5rem 0.75rem;
        background-color: #ffffff;
        color: #338bff;
        border: 1px solid #338bff;
        border-radius: 8px;

        &:hover {
          background-color: #338bff;
          color: #ffffff;
        }
      }
    }

    .input_individual_users_container {
      padding-top: 1.75rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .user_email_dropdown_container {
        margin-right: 1.625rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        height: auto;
        border: 1px solid #cdcdcd;
        border-radius: 8px;
        width: 80%;

        .user_email_input {
          font-size: 12px;
          width: 600px;

          & .MuiInputLabel-root {
            font-size: 14px;
          }

          & .MuiInputBase-root:hover {
            background-color: #ffffff;
          }

          & .MuiInputBase-root:not(hover) {
            background-color: #ffffff;
          }

          & .MuiFilledInput-root {
            background-color: #ffffff;
          }

          & .MuiInputBase-root {
            background-color: #ffffff;
            font-size: 14px;

            & .MuiFilledInput-input {
              background-color: #ffffff;
            }
          }

          & .MuiInputBase-root::after {
            background-color: #ffffff;
          }

          & .MuiOutlinedInput-notchedOutline {
            border: 0;
          }
        }

        .user_email_select {
          font-size: 14px;
          font-family: "Lato", sans-serif;
          font-weight: 600;
          height: 36px;
          min-width: 120px;
          box-shadow: none;

          .MuiOutlinedInput-notchedOutline {
            border: 0;
          }
          &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border: 0;
          }
          &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 0;
          }
        }
      }

      .add_user_button {
        height: 2.25rem;
        padding: 0;
        margin-right: 0;
        margin-left: 0;
        width: 20%;
      }
    }
  }

  .select_organization_users_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 2.375rem;
    border-bottom: 1px solid #cdcdcd;

    .select_org_users_instruction {
      font-size: 14px;
      font-family: Lato, sans-serif;
      font-weight: 600;
      color: #8384a1;
      margin-bottom: 1rem;
    }

    .select_all_checkbox {
      margin-bottom: 1rem;

      input {
        margin-right: 1rem;
      }

      label {
        color: #338bff;
      }
    }

    .org_users {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      overflow-y: auto;
      max-height: 350px;

      .org_user_button_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #f9f9fa;
        border: 1px solid #cdcdcd;
        border-radius: 8px;
        height: 3.25rem;
        margin-bottom: 1rem;
        width: 95%;

        .org_user_button {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          max-width: 250px;
          height: 100%;
          justify-content: space-between;
          border-radius: 8px;
          padding: 0.5rem 0.625rem;
          text-transform: none;

          .org_user_email {
            font-size: 14px;
            font-weight: 400;
            font-family: "Lato", sans-serif;
            text-overflow: ellipsis;
            color: #646372;
          }

          &.Mui-selected .org_user_email {
            color: #4ebe59;
          }
        }

        .org_user_select {
          font-size: 14px;
          font-family: "Lato", sans-serif;
          font-weight: 600;
          min-width: 140px;
          overflow: hidden;
          box-shadow: none;

          .MuiOutlinedInput-notchedOutline {
            border: 0;
          }

          &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border: 0;
          }

          &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 0;
          }

          &.Mui-focused .MuiSelect-iconOpen {
            margin-left: 12px;
            transform: rotate(90deg);
          }
        }

        .Mui-selected {
          background-color: #effff1;
        }
      }

      .org_user_button_container:has(.Mui-selected) {
        background-color: #effff1;
        border: 1px solid #4ebe59;
        border-radius: 8px;
        color: #4ebe59;
      }
    }
  }

  .added_users_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .people_with_access_header {
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }

    .added_users {
      width: 100%;
      height: 300px;
      max-height: 300px;
      overflow-y: auto;

      .added_user {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        height: 2.75rem;
        background-color: #f9f9fa;
        justify-content: space-between;
        margin-top: 0.75rem;
        border: 1px solid #cdcdcd;
        border-radius: 8px;

        label {
          color: #646372;
          font-size: 14px;
          font-family: "Lato", sans-serif;
          margin-bottom: 0;
          padding: 0.5rem 0.625rem;
          border-radius: 8px;
        }
      }

      .added_user_select {
        font-size: 12px;
        font-family: "Lato", sans-serif;
        font-weight: 600;
        min-width: 140px;
        box-shadow: none;

        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }

        &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border: 0;
        }

        &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border: 0;
        }

        &.Mui-focused .MuiSelect-iconOpen {
          margin-left: 12px;
          transform: rotate(90deg);
        }
      }

      .added_users_empty_state {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.share_entity_modal_footer {
  margin: 2.25rem;
  padding: 0;

  .share_modal_done_btn {
    margin: 0;
  }
}

.edit-app-modal {
  z-index: 1300;
  .header {
    font-size:24px;
    color: $black;
    font-family:"Exo 2";
    font-weight: 700;
    text-transform: capitalize;
    line-height: 28px;
  }
  .footer {
    margin-top: 2.25rem;
  }
}

.header {
  font-size:24px;
  color: $black;
  font-family:"Exo 2";
  font-weight: 700;
  text-transform: capitalize;
  line-height: 28px;
}
.footer {
  margin-top: 2.25rem;
}

.gp__pics {
  position: absolute;
  display: flex;
  justify-content: center;
  object-fit: contain;
  height: 200px;
  overflow: hidden;

  img {
    min-height: 250px;
    max-width: 100%;
  }
}

.act__list {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
}

.lds_notify {
  float: left;
  width: 100%;
}

.act__list li {
  border-bottom: 1px solid #eaeaea;
  float: left;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.lds_notify_img {
  float: left;
  width: 48px;
  border-radius: 100px;
  height: 48px;
}

.lds_notify_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.lds_notify_cont {
  float: right;
  width: calc(100% - 65px);
  text-transform: none;
}

.lds_notify_cont p {
  color: #000;
}

.lds_notify_cont p span {
  font-weight: 700;
}

#container__Scroll {
  position: relative;
  padding: 0px;
  height: 300px;
  overflow: auto;
}

.insights__more {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.insights__link {
  font-size: 15px;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: solid 1px transparent;
  padding-bottom: 0;
  margin: 15px auto 0;
  display: block;
  max-width: 167px;
}

.insights__link:hover,
.insights__link:focus {
  color: #9358d1;
  border-color: #9358d1;
}

.home__graph {
  float: left;
  width: 100%;
}

.home__multi {
  float: left;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 30px;
}

.home__multi .pr__bottom_Top h2 {
  float: left;
}

.home__multi .pr__bottom_Top .see__an {
  margin-top: 5px;
}

.home__multi .pr__bottom_Top {
  margin-top: 0;
  margin-bottom: 25px;
}

.home__Ludis {
  float: left;
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
}

.ludis__pic {
  float: left;
  width: 100%;
  height: 250px;
}

.ludis__pic img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  min-height: 100%;
  border-radius: 7px 7px 0 0;
}

.udis__body {
  padding: 15px;
  width: 100%;
  float: left;
}

.udis__body h5 {
  margin: 0;
  text-transform: none;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.3px;
  color: #000000;
  line-height: 25px;
}

.udis__body p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 0.3px;
  color: #000000;
}

.time_info {
  color: #a29f9f;
  font-size: 13px;
  margin-bottom: 0px;
}

.market__lus {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.market__lus .pr__bottom_Top h2 {
  float: left;
}

.market__lus .pr__bottom_Top .see__an {
  margin-top: 5px;
}

.market__lus .pr__bottom_Top {
  margin-top: 0;
  margin-bottom: 25px;
}

.market__lus .pr__bottom_Top h4 {
  float: left;
  margin-top: 35px;
  width: 100%;
}

.home__dash {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: solid 2px #c3c4c8;
}

.home__dash .pr__bottom_Top {
  float: left;
  margin-bottom: 10px;
}

.home__dash .pr__bottom_Top h2 {
  float: left;
}

.home__dash .pr__bottom_Top .see__an {
  margin-top: 5px;
}

.pr__BX_3 {
  background: url("../images/thumbnails/alg_2.png") left top;
  background-color: #476bf7;
  background-repeat: no-repeat;
}

.pr__BX_4 {
  background: url("../images/thumbnails/alg_1.png") left top;
  background-color: #9a51e0;
  background-repeat: no-repeat;
}

.datasets__card {
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  padding: 20px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}

.detasets__block {
  float: left;
  width: 100%;
  border-bottom: solid 2px #c3c4c8;
  padding-bottom: 30px;
  margin-bottom: 40px;
}

.detasets__block .pr__bottom_Top {
  margin-top: 0;
  margin-bottom: 25px;
}

.datasets_image {
  height: 250px;
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.datasets_anchor {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
}

.datasets_anchor:hover {
  opacity: 0.8;
}

.datasets_anchor img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  min-height: 100%;
}

.bg__im2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progress-bar {
  background-color: $primary-color;
}

.datasets_image::before {
  content: " ";
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  left: 0;
  bottom: 0;
}

.data_anchor {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 20px;
}

.theme_anchor {
  display: inline-block;
  text-align: center;
  font-size: 17px;
  color: #ffffff;
  background: #9358d1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  padding: 6px 15px;
  border-radius: 4px;
  border: 1px solid #9358d1;
  transition: all 0.5s;

  code {
    color: white;
  }
}

.theme_anchor:hover {
  transition: all 0.5s;
  color: #9358d1;
  background: #ffffff;
}

.datasets__bidy {
  padding: 20px 0px;
  width: 100%;
  float: left;
}

.datasets__bidy h5 {
  margin: 0;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.3px;
  color: #000000;
  line-height: 25px;
}

.datasets__bidy h5 a {
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #000000;
  line-height: 25px;
}

.datasets__bidy p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #000000;
}

.datasets__footer li {
  display: inline-block;
  margin-right: 10px;
}

.figure_datasets {
  margin: 4px 7px 0 0;
  width: 18px;
  height: 18px;
  display: block;
  overflow: hidden;
  float: left;
}

.figure_datasets img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  min-height: 100%;
}

.figure_info {
  color: #a29f9f;
  font-size: 14px;
  letter-spacing: 0.3px;
  display: inline-block;
  font-weight: 500;
}

/*--lds Home End--*/
/*--lds Datasets Start--*/
.dataset_tabs {
  margin-left: 0 !important;
}

.datasets_image2 {
  &:before {
    display: none;
  }
}

.datasets__card_dl {
  margin-top: 30px;
}

.lds_il_right_btn {
  ul {
    float: right;

    li {
      float: left;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;

        a {
          color: $black;
          background: transparent;
          border: transparent;

          &:hover {
            background: transparent;
            border: transparent;
            color: $primary-color;
          }
        }
      }

      &:nth-child(2) {
        margin-left: 0;

        a {
          background: transparent;
          border: 1px solid $black;
          color: $black;

          &:hover {
            background: $primary-color;
            border: 1px solid $primary-color;
            color: $white;
          }
        }
      }

      &:last-child {
        a {
          background: $primary-color;
          border: 1px solid $primary-color;
          color: $white;

          &:hover {
            background: transparent;
            color: $black;
            border: 1px solid $black;
          }
        }
      }
    }
  }
}

.menu_active {
  color: $primary-color !important;

  span {
    color: $primary-color !important;
  }
}

.s3_object_modal {
  margin-top: 100px;
  padding-bottom: 100px;
  text-align: left;
}

.link_div {
  background-color: rgb(238, 235, 235);
  padding: 5px;
  border-radius: 3px;
  text-align: left;
  overflow-wrap: break-word;
}

.url_info {
  font-weight: bold;
  font-size: 14pt;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 5px;
    cursor: pointer;
  }
  .info_left {
    display: flex;
  }
}

.directory_list {
  li {
    padding-top: 10px;

    .dataset_file_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .dataset_file_row_icons {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.dataset_folder {
  &:hover {
    color: $primary-color;
  }
}

.directory_file {
  max-width: 60%;
  display: inline-block;
  .object_icn {
    font-size: 18px;
  }
}

.infinite-scroll-component {
  overflow: visible !important;
}

.add_dir_modal {
  margin-top: 30px;
}

.lds_dts_tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;

  h4 {
    display: inline;
  }

  .top_row_icons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .show_pin {
    margin-top: -10px;
  }

  .fld_icn {
    margin-right: -9px;
  }

  .file_icn {
    margin-top: -3px;
    margin-right: -5px;
  }

  .download_all_icn {
    margin-right: 5px;
  }
}

/*--lds Datasets End--*/
/*--lds edit notebook Start--*/
.lds_en_main_wrapper {
  .lds_en_top_header {
    padding: 30px;
    position: fixed;
    top: 0;
    background: #f5f6fa;

    .lds_en_top_left {
      float: left;
      width: 50%;
      position: relative;
      padding-left: 35px;
      padding-top: 3px;

      a {
        position: absolute;
        left: 0;
        top: -2px;
      }

      h4 {
        font-weight: $ft_semibold_600;
      }

      p {
        font-weight: $ft_medium_500;
        line-height: 24px;
      }
    }

    .lds_en_top_right {
      float: left;
      width: 50%;

      ul {
        float: right;

        li {
          float: left;
          margin-left: 15px;

          &:first-child {
            margin-left: 0;

            a {
              color: $black;
              background: transparent;
              border: transparent;

              &:hover {
                background: transparent;
                border: transparent;
                color: $primary-color;
              }
            }
          }

          &:nth-child(2) {
            a {
              background: transparent;
              border: 1px solid $black;
              color: $black;

              &:hover {
                background: $primary-color;
                border: 1px solid $primary-color;
                color: $white;
              }
            }
          }

          &:last-child {
            a {
              background: $primary-color;
              border: 1px solid $primary-color;
              color: $white;

              &:hover {
                background: transparent;
                color: $black;
                border: 1px solid $black;
              }
            }
          }
        }
      }
    }
  }

  .lds_en_ia_top_header {
    border-bottom: 2px solid #c3c4c8;
  }

  .lds_en_body {
    margin-top: 110px;
    background: $white;
    float: left;
    width: calc(100% - 390px);
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;

    .lds_en_navigation {
      padding: 0 30px;
      padding-right: 0;
      position: fixed;
      left: 0;
      background: $white;
      width: calc(100% - 390px);
      -moz-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
      -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
      -webkit-transition: $transition;
      -o-transition: $transition;
      -ms-transition: $transition;
      -moz-transition: $transition;
      transition: $transition;

      .lds_en_left_menu {
        float: left;
        width: 40%;

        ul {
          float: left;

          li {
            float: left;
            margin-right: 15px;

            &:last-child {
              margin-right: 0;
            }

            a {
              float: left;
              color: $black;
              font-weight: $ft_medium_500;
              padding: 20px 0;

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }

      .lds_en_right_menu {
        float: left;
        width: 60%;

        ul {
          float: right;

          li {
            float: left;
            margin-left: 20px;

            &:first-child {
              margin-left: 0;
            }

            a {
              float: left;
              color: $black;
              font-weight: $ft_medium_500;
              padding: 20px 0;

              &:hover {
                color: $primary-color;
              }

              img {
                position: relative;
                top: -1px;
              }
            }

            &:last-child {
              margin-left: 10px;

              a {
                background: #f5f6fa;
                padding: 20px 10px;
              }
            }

            .lds_po_filter_dropdown .nice-select {
              width: auto;
              height: auto;
              border: 0;
              border-radius: 0;
              background: transparent;
              line-height: 30px;
              padding: 0;
              padding-right: 28px;
              padding-top: 19px;
              padding-bottom: 17px;

              &:after {
                width: 7px;
                height: 7px;
                border-bottom: 1px solid $black;
                border-right: 1px solid $black;
              }
            }

            .lds_po_filter_dropdown li a {
              float: left;
              width: 100%;
              padding: 10px 15px;
              font-size: 16px;
              color: $black;
              font-weight: $ft_medium_500;
              border-bottom: 1px solid #ededed;
              -webkit-transition: $transition;
              -o-transition: $transition;
              -ms-transition: $transition;
              -moz-transition: $transition;
              transition: $transition;
            }

            .lds_po_filter_dropdown .nice-select.open .current {
              color: $primary-color;
            }

            .lds_po_filter_dropdown .nice-select.open:after {
              -webkit-transform: rotate(-135deg);
              -ms-transform: rotate(-135deg);
              transform: rotate(-135deg);
              border-bottom: 1px solid $primary-color;
              border-right: 1px solid $primary-color;
            }

            .lds_po_filter_dropdown .current {
              color: $black;
              font-weight: $ft_medium_500;
              font-size: 16px;
            }

            .lds_po_filter_dropdown .list {
              width: 210px;
              left: auto;
              right: 0;
              margin-top: 0;
              box-shadow: none;
              -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
              -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
              -o-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
              -ms-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);
              box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);

              li {
                margin-left: 0;
                width: 100%;

                a {
                  background: transparent;

                  i {
                    &:before {
                      font-size: 14px;
                      color: $black;
                      padding-right: 5px;
                      -webkit-transition: $transition;
                      -o-transition: $transition;
                      -ms-transition: $transition;
                      -moz-transition: $transition;
                      transition: $transition;
                    }
                  }

                  &:hover {
                    color: $primary-color;

                    i {
                      &:before {
                        color: $primary-color;
                        -webkit-transition: $transition;
                        -o-transition: $transition;
                        -ms-transition: $transition;
                        -moz-transition: $transition;
                        transition: $transition;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .lds_en_dash_body {
      padding-top: 135px;

      img {
        width: 100%;
      }
    }
  }

  .lds_en_sidebar {
    width: 390px;
    height: calc(100% - 110px);
    position: fixed;
    right: 0;
    top: 110px;
    z-index: 100;
    background: $white;
    -moz-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;

    .lds_en_sidebar_toggle {
      position: absolute;
      left: -32px;
      top: 40%;

      a {
        background: $white;
        padding: 15px 10px;
        float: left;
        -moz-box-shadow: -7px 2px 15px 0 rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: -7px 2px 15px 0 rgba(0, 0, 0, 0.06);
        box-shadow: -7px 2px 15px 0 rgba(0, 0, 0, 0.06);
        border-top-left-radius: 10px;
        -moz-border-top-left-radius: 10px;
        -khtml-border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        -moz-border-bottom-left-radius: 10px;
        -khtml-border-bottom-left-radius: 10px;
      }
    }
  }
}

.accordion {
  width: 100%;
  background: $white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.accordion .link {
  cursor: pointer;
  display: block;
  color: $black;
  font-size: 18px;
  position: relative;
  font-weight: $ft_semibold_600;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion li i.fa-chevron-down {
  right: 0;
  top: 4px;
  left: auto;
  font-size: 16px;
}

.accordion li.open .link {
  color: $black;
  font-size: 18px;
}

.accordion li.open i {
  color: $primary-color;
}

.cc_pc_icon {
  font-size: 20px !important;
}

.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion li.default .submenu {
  display: block;
}

.accordion .cc_product_heading i {
  top: 18px;
}

/**
 * Submenu
 -----------------------------*/
.submenu {
  display: none;
  background: $white;
}

.lds_en_sidebar_inner {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f6fa;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f6fa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-color;
  }
}

.lds_en_sidebar_accor_box1 {
  padding: 20px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;

  .lds_en_accor_cont1 {
    .lds_en_accor_cont1_pera1 {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #f2f2f2;

      p {
        max-width: 90%;
        color: $black;
        line-height: 19px;
        font-size: 14px;
      }

      h6 {
        text-transform: uppercase;
        color: #7c7c7c;
        font-size: 12px;
        margin-top: 20px;
        padding-bottom: 3px;
      }
    }
  }

  .lds_en_accor_cont1_pera2 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    float: left;
    width: 35%;
    border-bottom: 0 !important;
  }

  .lds_en_accor_cont1_pera3 {
    border-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    float: left;
    width: 65%;
  }

  .lds_en_accor_cont2 {
    float: left;
    width: 100%;
    margin-top: 20px;

    .lds_en_nw_img {
      float: left;
      width: 95px;
    }

    .lds_en_nw_img_cont {
      float: left;
      width: calc(100% - 95px);
      padding-top: 15px;

      h4 {
        a {
          font-weight: $ft_semibold_600;
          color: $black;
        }
      }
    }
  }

  .lds_en_accor_cont3 {
    padding-top: 15px;

    ul {
      li {
        text-transform: uppercase;
        color: #7c7c7c;
        font-size: 12px;
        font-weight: $ft_semibold_600;
        padding: 5px 0;
        border-bottom: 1px solid #f2f2f2;

        span {
          text-transform: capitalize;
          color: $black;
          font-size: 14px;
          font-weight: $ft_semibold_600;
          padding-left: 10px;
        }
      }
    }

    a {
      padding: 10px 12px;
      font-size: 12px;
      border: 1px solid $black;
      color: $black;
      background: transparent;
      margin-top: 15px;

      &:hover {
        border: 1px solid $primary-color;
        color: $white;
        background: $primary-color;
      }
    }
  }

  .lds_en_accor_cont4 {
    padding: 20px 0;
    border-bottom: 1px solid #f2f2f2;
    float: left;
    width: 100%;

    .lds_en_dis_img {
      float: left;
      width: 55px;
    }

    .lds_en_dis_img_cont {
      float: left;
      width: calc(100% - 55px);

      h4 {
        a {
          font-weight: $ft_semibold_600;
          color: $black;
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
        line-height: 17px;
      }
    }

    .lds_en_dis_input {
      float: left;
      width: 100%;

      input {
        width: 100%;
        height: 55px;
        padding-left: 20px;
        border-radius: 10px;
        -moz-border-radius: 10px;
        -khtml-border-radius: 10px;
        border: 1px solid #e5e5e5;
        margin-top: 20px;
      }
    }
  }

  .lds_en_accor_cont5 {
    border-bottom: 0;
  }
}

body.active .lds_en_sidebar {
  right: -390px;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

body.active .lds_en_body {
  width: 100%;
}

body.active .lds_en_navigation {
  width: 100% !important;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

.help-icon-button-container {
  display: inline-block;
  margin-top: -10px;
}

.help-icon-button {
  padding: 8px;
}

.help-icon-button:focus {
  outline: none;
}

.help-icon-button-container:hover {
  background-color: transparent;
}

.help-icon-image {
  width: 16px;
  height: 16px;
}

.errors-label {
  color: red;
  font-weight: bold;
}

.error-count {
  color: white;
  background-color: red;
  border-radius: 50%;
  padding: 2px 6px;
  font-weight: bold;
  margin-left: 5px;
}

.clickable {
  cursor: pointer;
}

.show-text {
  color: $primary-color;
  cursor: pointer;
  padding-left: 10px;
}

.error-container {
  background-color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.error-item {
  background-color: #ffeeea;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
  color: #ed4521;
  border-radius: 10px;
}

.status-cell {
  font-weight: bold;
}

.status-content {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-broken .status-content,
.status-failed .status-content {
  background-color: #ffeeea;
  color: #ed4521;
}

.status-success .status-content,
.status-active .status-content,
.status-running .status-content {
  background-color: #effff1;
  color: #4ebe59;
}

.status-unpublished .status-content {
  color: #6b71fb;
  background-color: #e4e7ff;
}
.left_align_button {
  display: inline-block;
  background-color: transparent;
  color: $primary-color;
  cursor: pointer;
  font-size: 0.75rem;
}

.status-initializing .status-content {
  background-color: #ebf4ff;
  color: #338bff;
}

.status-cell {
  color: black;
}

.status-cell .status-content {
  border-radius: 5px;
  padding: 8px 8px;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.freq-buttons {
  display: flex;
}

.freq-button {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  padding: 0.25rem;
  margin: 0.2rem;
  border: 1px solid #ccc;
  font-size: 0.8rem;
  cursor: pointer;
  pointer-events: auto;
  outline: none;
}

.freq-button.active {
  color: white;
  background-color: $primary-color;
}

.confirmation-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.run-workflow-icon {
  font-size: 1.5rem;
  color: $primary-color;
  border-radius: 10rem;

  &:hover {
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
    color: white;
    background-color: $primary-color;
    cursor: pointer;
  }
}

.no-schedule-button {
  background-color: $primary-color;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px;
  outline: none;
}

.no-schedule-button.no-schedule {
  background-color: white;
  color: $primary-color;
}

.disabled-element {
  opacity: 0.3;
}

.workflow_header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.workflow_header h4 {
  margin-right: 8px;
}

.delete-workflow-modal .modal-dialog {
  background: transparent;
}

.card_container {
  margin-top: 10px;
}

.current-task-labels {
  margin-left: 20px;
}

.current-task-labels p {
  color: #646372 !important;
  display: flex;
  flex-direction: column;
  min-width: 50px;
}

.current-task-variables {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.current-task-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.project-card {
  background-color: white;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 16px;
  max-width: 250px;
  min-width: 250px;

  .navlink-active {
    color: #6B71FB;
  }

  .navlink-inactive {
    color: gray;
    pointer-events: none;
  }

  .project-link-icon {
    font-size: 16px;
    color: #888;
    margin-top: 6px;
  }
  .project-link-name {
    gap: 10px;
    color: #888;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
  }
}

.project-card-content {
  overflow-x: auto;
}

.project-card-title {
  font-size: 18px;
  margin-top: 10px;
  margin-left: 15px;
}

.project-card-title {
  font-size: 18px;
  margin-top: 10px;
}

.project-card-header {
  margin-bottom: 8px;
}

.project-card-header p {
  font-size: 12px;
  font-weight: bold;
  color: #888;
  margin: 0;
}

.project-card-footer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  #white_btn {
    background: white;
    color: $primary-color;
    display: flex;
    align-items: center;
    width: 90%;
    height: 30px;
  }
}

.project-card-icon {
  margin-right: 5px;
  font-size: 1.25rem !important;
}

.logs-card {
  width: 800px;
}

.logs-title {
  display: flex;
  align-items: center;

  h4 {
    font-size: 18px;
    margin-left: 10px;
  }
}

.active-tooltip {
  display: inline-block;
}

.clear-task-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clear-task-title {
  padding: 15px;
}

.view-code-button {
  padding: 0;
  margin: 0;
  background: $white;
  color: $primary-color;
  font-size: 0.75rem;
  text-align: center;
  letter-spacing: 0.0633rem;
  border: 0;
  cursor: pointer;
}

.disabled-link {
  pointer-events: none;
  color: gray !important;
  text-decoration: none;
  cursor: not-allowed;
}

.scrollable-paper {
  width: 100%;
  height: 600px;
  overflow-x: auto;
  overflow-y: auto;
  padding: 15px;
}

.scrollable-content {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

//Modal css for notebook import
.import_modal {
  margin-top: 10px;

  .modal-body {
    padding-top: 10px !important;
  }

  .modal_detail {
    padding-top: 8px;

    .modal_icon {
      display: inline;
      padding-top: 3px;
      margin-right: 6px;

      img {
        margin-top: -3px;
      }
    }
  }
  .header {
    font-size: 28px;
    color: $black;
    font-family:"Exo 2";
    font-weight: 700;
    text-transform: capitalize;
    line-height: 28px;
  }
}

.publish_workflow {
  min-height: 385px;
}

.time-input {
  display: flex;
  position: relative;
}

.day-input {
  display: flex;
  align-items: center;
}

.timezone {
  margin-left: 10px;
  margin-top: 10px;
  height: 100%;
  border: 5px solid #fff;
}

.schedule-week {
  pointer-events: none;
}

.freq_select {
  margin-left: 5px;
  height: 100%;
  border: 5px solid #fff;
  cursor: pointer;
}

.file-list {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.file_select {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.file-list p {
  font-weight: bold;
}

.file-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.file-list li {
  margin-bottom: 5px;
}

.file-list label {
  margin-left: 5px;
}

.centered-button {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.centered-text {
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem;
}

.checkbox {
  margin-right: 5px;
  transform: scale(0.8);
}

.project_button {
  display: flex;
  align-items: center;
  background-color: #e9ebff;
  color: #5e6ec3;
  padding: 8px 16px;
  border-radius: 10px;
  border: white;
  cursor: pointer;
  margin-right: 10px;
  width: 150px;
  height: 150px;
  margin: 40px;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
}

.project_button_text {
  text-align: center;
}

.publish-dialog-box {
  .modal-header
    {
      font-size: 24px;
      color: $black;
      font-family:"Exo 2";
      font-weight: 700;
      text-transform: capitalize;
      line-height: 28px;
    }
  .redeploy_selection_container {
    padding: 20px;
    p {
      margin-bottom: 10px;
    }
    button {
      margin: 10px;
    }
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
  .css-ypiqx9-MuiDialogContent-root {
    overflow-y: visible;
  }
}

.button_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

//Css for publish notebook
.publish_modal {
  text-align: left;
  padding-top: .5rem;

  input {
    height: 45px;
    width: 100%;
    font-size: 16px;
    padding-left: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 8px;
    -moz-border-radius: 15px;
    -khtml-border-radius: 15px;
    border: 1px solid #dddddd;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
    font-weight: 400;

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover,
    &:focus {
      -webkit-transition: $transition;
      -o-transition: $transition;
      -ms-transition: $transition;
      -moz-transition: $transition;
      transition: $transition;
      border: 1px solid $primary-color;
    }
  }

  .asterisk {
    color: orange; /* Set the color for the asterisk */
  }

  .publish_project_text {
    color: black;
    font-size: 15px;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  textarea {
    width: 100%;
    font-size: 18px;
    padding-left: 20px;
    padding-top: 15px;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -khtml-border-radius: 15px;
    border: 1px solid #dddddd;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
    resize: none;
    margin-top: 10px;

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover,
    &:focus {
      -webkit-transition: $transition;
      -o-transition: $transition;
      -ms-transition: $transition;
      -moz-transition: $transition;
      transition: $transition;
      box-shadow: none;
      outline: none;
      border: 1px solid $primary-color;
    }
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    padding-top: 15px;
    margin-bottom: 2px;
  }
}

.publish_file_button {
  background-color: white;
  color: black;
  border: 2px solid rgb(204, 200, 200);
  border-radius: 10px;
  padding: 6px 12px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-size: 14px;
  text-align: center;
  position: relative;
}

.publish_workflow_text {
  font-size: 12px;
  color: grey;
  padding-top: 15px;
}

.publish_back_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: $primary-color;
  border: 2px white;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 10px;
  margin: 10px;
}

.initalize_workflow_button {
  background-color: white;
  color: $primary-color;
  font-size: 14px;
}

.details-container {
  display: flex;
}

.selected {
  background-color: $primary-color;
  color: white;
  border-color: darkgrey;
}

.file_select_container {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.publish_file_button:hover {
  background-color: $primary-color;
  color: white;
  border-color: darkgrey;
}

.publish_file_select_row {
  display: flex;
  align-items: center;
  input,
  label {
    align-self: center;
    margin: 5px;
    cursor: pointer;
  }
}

.other_files {
  padding-top: 20px;
  .publish-workflow-text {
    color: black;
  }
}
.purple_text {
  color: $primary-color;
}

.css-13cymwt-control {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
}

.publish_select_btn {
  .css-2b097c-container .css-1hwfws3 {
    height: 55px !important;
  }

  .css-1uccc91-singleValue {
    top: 60% !important;
  }
  .css-13cymwt-control {
    border-radius: 8px;
  }
}

.publish_radio_btn {
  float: left;
  width: 100%;
  cursor: pointer;
}

.publish_notebooks {
  margin-top: 10px;
  align-items: center;
}

.dag-circle {
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  margin-left: 40px;
}

.task-list {
  display: flex;
  margin-left: 15px;
  white-space: nowrap;
}

.task-square {
  width: 15px;
  height: 15px;
  border-radius: 10%;
  margin: 0px 2px;
}

.dag-date {
  font-size: 12px;
  width: 45px;
}

.task-row {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.workflow-select {
  text-align: left;
  margin: 10px;
}
/*--lds edit notebook End--*/
/*--lds insight dashboard Start--*/
.gp__picsf {
  height: auto;
}

.custom-control-label {
  cursor: pointer;
}

.shared_header_apps {
  padding-top: 15px;
  margin-bottom: -10px;
  margin-left: 15px;
}

.shared_header_reps {
  padding-top: 15px;
  margin-bottom: -10px;
  margin-left: 15px;
}

.pagination_container {
  text-align: center;
}

.entity_pagination {
  margin-top: 20px;
  display: inline-block;
}

.tile_header {
  width: 90%;
  top: 40px;
  left: 15px;
  position: relative;
  z-index: 1;
}

.home_card_id {
  margin-bottom: 0;
}

.home_card_ia {
  position: relative;

  &:after {
    content: "";
    background: rgba(0, 15, 80, 0.486);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $border-rad5;
    -moz-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
  }

  small {
    background: #ff5f49;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    font-size: 11px;
    padding: 0px 5px;
    line-height: 16px;
    float: right;
    position: absolute;
    right: 33px;
    top: 6px;
  }
}

.home_card_ia3 {
  &:after {
    background: rgba(255, 95, 73, 0.6);
  }
}

.home_card_ia4 {
  &:after {
    background: rgba(0, 76, 255, 0.2);
  }
}

.lds_ia_cont {
  position: relative;
  z-index: 10;
  top: 180px;
  margin: 0px 20px;

  .app_info_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 70px;

    .app_title {
      display: flex;
      flex-direction: column;
      width: 80%;
      word-wrap: break-word;
    }

    .views_count {
      display: flex;
      align-items: center;
      color: rgb(199, 202, 217);

      svg {
        margin: 5px;
      }
    }
  }

  h4 {
    color: $white;
    font-weight: $ft_semibold_600;
    text-transform: uppercase;
    line-height: 26px;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    font-weight: $ft_semibold_600;
    text-transform: uppercase;
    font-size: 14px;
  }
}

.lds_en_accor_cont2 {
  float: left;
  width: 100%;
  margin-top: 20px;

  .lds_en_nw_img {
    float: left;
    width: 95px;
  }

  .lds_en_nw_img_cont {
    float: left;
    width: calc(100% - 95px);
    padding-top: 15px;

    h4 {
      a {
        font-weight: $ft_semibold_600;
        color: $black;
      }
    }
  }

  .lds_en_nw_img_cont_dd {
    h4 {
      a {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.lds_dd_set_btn {
  a {
    background: transparent;
    border: 1px solid $black;
    color: $black;
    font-size: 12px;
    padding: 10px 12px;
    margin-top: 20px;

    &:hover {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: $white;
    }
  }
}

.lds_ddt_over_box {
  padding: 5px 30px 30px;
  background: #ffffff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  margin-top: 30px;

  .lds_ddt_inner_box {
    h4 {
      font-weight: $ft_medium_500;

      span {
        font-size: 16px;
        font-weight: $ft_regular_400;
      }
    }

    ul {
      li {
        float: left;
        width: 100%;

        a {
          color: $black;
          font-weight: $ft_medium_500;
          float: left;
          width: 100%;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    p {
      padding-top: 12px;
    }
  }

  .lds_ddt_inner_box_last {
    h4 {
      font-weight: $ft_medium_500;
      padding-bottom: 20px;
      border-bottom: 1px solid #ededed;

      span {
        font-size: 16px;
        font-weight: $ft_regular_400;
      }
    }

    ul {
      margin-top: 10px;
      float: left;

      li {
        margin-top: 2px;

        a {
          font-weight: $ft_medium_500;
          color: $black;

          span {
            color: $primary-color;
          }

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.lds_ddt_over_box_last {
  margin-bottom: 60px;
}

/*--lds insight dashboard End--*/
/*--lds market place Start--*/
.hero__Pol .bg__pic::before {
  content: " ";
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  right: 0;
  left: 0;
  bottom: 0;
}

.sp__Snall {
  color: #93959d !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.home__Intro p {
  width: 100%;
  color: #fff;
  font-size: 20px;
  margin: 15px 0;
  max-width: 600px;
  display: block;
  letter-spacing: 0.7px;
}

.home__Supp .hero__Pol .bg__pic::before {
  display: none;
}

.home__Intro a {
  display: inline-block;
  text-align: center;
  font-size: 13px;
  color: #ffffff;
  background: #9358d1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #9358d1;
  transition: all 0.5s;
  margin-top: 10px;
}

.home__Intro a:hover,
.home__Intro a:focus {
  background: #fff;
  border-color: #fff;
  color: #9358d1;
}

.market__Supp .lds_right_box_wrapper {
  padding-top: 20px;
}

.market__Supp .datasets__bidy {
  padding: 10px 0px;
}

.pr__BX_5 {
  background: url("../images/thumbnails/game__orr.jpg") left top;
  background-color: #ff5f49;
  background-repeat: no-repeat;
}


.market__Supp .market__lus {
  border-bottom: solid 2px #c3c4c8;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.market__Supp .insights__link {
  margin-top: 45px;
  max-width: 200px;
}

.inside__block {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 30px;
  border-bottom: solid 2px #c3c4c8;
}

.inside__block .pr__bottom_Top {
  margin-top: 30px;
}

.detasets__block .bg__im2 {
  max-width: 100%;
}

.inside__block .datasets_image::before {
  display: none;
}

.inside__block .datasets_anchor:hover {
  opacity: 0.7;
}

.market__Supp .home__Title {
  -webkit-text-stroke: 0 #000;
}

.market__Supp .datasets__bidy h5 {
  margin-bottom: 5px;
}

/*--lds market place End--*/
/*--lds insights app Start--*/
.pr_ia_select {
  text-align: left;
  margin-top: 25px;

  label {
    font-weight: $ft_semibold_600;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #acabb0;
    font-size: 14px;
  }

  .nice-select {
    width: 100%;
    height: 55px;
    padding-right: 40px;
    border: 1px solid #e5e5e5;
    border-radius: $border-rad5;
    -moz-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
    font-size: 16px;
    color: $black;
    font-weight: $ft_regular_400;
    line-height: 51px;

    .list {
      width: 100%;
    }

    &:after {
      width: 9px;
      height: 9px;
      right: 15px;
      top: 46%;
      border-bottom: 2px solid $body-color;
      border-right: 2px solid $body-color;
    }
  }
}

.lds_ai_body {
  background: transparent !important;
  padding: 0 30px;
}

/*--lds insights app End--*/
/*--lds chat box Start--*/
/***----chat box wrapper css------****/
#chat-circle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: white;
  padding: 0;
  z-index: 100;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 0.7);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 0.7);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 0.7);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 0.7);

  img {
    width: 27px;
  }
}

.btn-raised {
  background: $black;
  box-shadow: 0 0 0 0 rgba(43, 49, 141, 0.18);
  border: 6px solid $black;
}

.btn#my-btn {
  background: white;
  padding-top: 13px;
  padding-bottom: 12px;
  border-radius: 45px;
  padding-right: 40px;
  padding-left: 40px;
  color: #5865c3;
}

.chat-box {
  display: none;
  position: fixed;
  right: 30px;
  z-index: 10000;
  bottom: 34px;
  background: #fff;
  width: 350px;
  max-width: 85vw;
  max-height: 100vh;
  border-radius: 5px;
  -ms-box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
}

.help-box {
  display: none;
  position: fixed;
  right: 120px;
  z-index: 10000;
  bottom: 34px;
  background: #fff;
  width: 400px;
  max-width: 85vw;
  max-height: 100vh;
  border-radius: 5px;
  -ms-box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 35px rgba(0, 0, 0, 0.1);
}

.chat-box-toggle,
.help-box-toggle {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 8px;
}

.chat-box-header {
  background: $primary-color;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  font-size: 20px;
  padding-top: 17px;
  padding-left: 30px;
  position: relative;

  h4 {
    font-size: 14px;
    color: $white;
  }

  p {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.78);
    line-height: 16px;
    padding-top: 10px;
  }

  img {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.help-box-header {
  background: #fade8c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  font-size: 20px;
  padding-top: 17px;
  text-align: center;
  position: relative;

  h4 {
    font-size: 26px;
    font-weight: 600;
    color: #fade8c;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.chat-box-body {
  position: relative;
  height: 370px;
  height: auto;
  background: #f5f6fa;
  width: 100%;
  overflow: hidden;
}

.help-box-body {
  position: relative;
  height: 370px;
  height: auto;
  background: #fade8c;
  width: 100%;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;

  .pd_login_contact_detail_wrapper {
    text-align: center;
    padding-top: 30px;

    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #fade8c;
    }

    p {
      color: #fade8c;
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }

  .pd_login_contact_list_wrapper {
    img {
      float: left;
      width: 35px;
    }

    span {
      float: left;
      width: calc(100% - 35px);
      padding-left: 15px;
      padding-top: 3px;
      font-size: 18px;
      color: #fade8c;

      a {
        font-size: 18px;
        color: #fade8c;
      }
    }

    li {
      float: left;
      width: 100%;
      margin-top: 20px;

      svg {
        width: 35px;
        float: left;
        fill: #fade8c;
      }

      &:last-child {
        span {
          font-weight: 600;
          padding-top: 6px;
        }
      }
    }

    p {
      font-size: 14px;
      color: #000;
      padding-top: 100px;
      float: left;
      width: 100%;
      padding-bottom: 60px;
      text-align: center;
    }
  }
}

#chat-input {
  background: #fff;
  width: 100%;
  position: relative;
  height: 50px;
  padding-top: 10px;
  padding-right: 50px;
  padding-bottom: 10px;
  padding-left: 15px;
  border: none;
  resize: none;
  outline: none;
  border: 1px solid #e2e2e2;
  color: #777;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}

.change {
  svg {
    fill: #fff !important;
  }
}

.chat-input > form {
  margin-bottom: 0;
}

#chat-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777;
}

#chat-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #777;
}

#chat-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #777;
}

#chat-input:-moz-placeholder {
  /* Firefox 18- */
  color: #777;
}

.chat-submit {
  position: absolute;
  bottom: 7px;
  right: 10px;
  background: transparent;
  box-shadow: none;
  border: none;
  border-radius: 50%;
  color: $primary-color;
  width: 35px;
  height: 35px;
  font-size: 18px;
}

.chat-logs {
  padding: 15px;
  height: 370px;
  overflow-y: auto;
}

.chat-logs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-color;
}

.chat-logs::-webkit-scrollbar {
  width: 5px;
  background-color: $primary-color;
}

.chat-logs::-webkit-scrollbar-thumb {
  background-color: $primary-color;
}

@media only screen and (max-width: 500px) {
  .chat-logs {
    height: 40vh;
  }
}

.chat-msg.user > .msg-avatar img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  float: left;
  width: 15%;
  position: relative;
  z-index: 10;
}

.chat-msg.self > .msg-avatar img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  float: right;
  width: 15%;
  z-index: 10;
}

.cm-msg-text {
  background: $primary-color;
  padding: 8px 25px 8px 25px;
  color: $white;
  z-index: 10000000;
  max-width: 75%;
  float: left;
  margin-left: 10px;
  position: relative;
  border-radius: 20px;
  margin-top: 12px;
}

.chat-msg {
  clear: both;
  float: left;
  width: 100%;
  margin-bottom: 25px;
}

.chat-msg.self > .cm-msg-text {
  float: right;
  margin-right: 10px;
  background: $primary-color;
  color: $white;
  position: relative;
  z-index: 10;
}

.user span img {
  float: right;
}

.cm-msg-button > ul > li {
  list-style: none;
  float: left;
  width: 50%;
}

.chat-msg img {
  position: relative;
  z-index: 100;
}

.cm-msg-button {
  clear: both;
  margin-bottom: 70px;
}

.previewPortNumber {
  margin-top: 15px;
  margin-right: 15px;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

/*-- pd Chat Box main wrapper End --*/
// The main container element
.Collapsible {
  background-color: white;
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-decoration: none;
  position: relative;
  background: transparent;
  color: black;

  &:after {
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 20px;
    top: 3px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }
}

.conversationContainer {
  height: 200px;
}

.form_img_selector {
  display: flex;
  justify-content: left;
  margin: 15px;
  padding-bottom: 10px;
}

.img_selector_wrapper {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-top: 1rem;

  .change-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }

    svg {
      height: 16pt;
    }
  }

  .img-preview {
    overflow: hidden;
    width: 150px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;

    img {
      height: 150px;
    }

    svg {
      font-size: 28pt;
    }
  }

  .img-options {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 1rem;
    color: $primary-color;

    .option {
      display: flex;
      align-items: center;
      margin: 5px;
      cursor: pointer;

      &:hover {
        color:#305ed2;
      }

      svg {
        height: 16pt;
      }
    }

    input[type="file"] {
      display: none;
      position: absolute;
    }

    label {
      cursor: pointer;
      margin: 0px;
    }
  }
}

.react-flow .react-flow__handle {
  width: 30px;
  height: 14px;
  border-radius: 3px;
  background-color: #784be8;
}

.react-flow .react-flow__handle-top {
  top: -10px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -10px;
}

.react-flow .react-flow__node {
  height: 40px;
  width: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-width: 2px;
  font-weight: 700;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
}

.img-input-wrapper {
  display: flex;
  align-items: center;
}

.img-selector-modal {
  height: 100%;
  max-width: 615px;
  position: absolute;
  padding-top: 0.5rem;
  top: 0rem;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  border-radius: 3px;

  .imgs-section {
    overflow-y: auto;
    overflow-x: hidden;

    img {
      height: 100px;
      margin: 10px;
      cursor: pointer;
      border-radius: 10px;
    }

    .selected-img {
      border: solid 5px;
      color: $primary-color;
      border-radius: 10px;
    }
  }

  svg {
    cursor: pointer;
    float: left;
    margin-left: 10px;
  }
}

.standard_text_input {
  height: 45px;
  font-size: 18px;
  border-radius: $border-rad5;
  -moz-border-radius: $border-rad5;
  -khtml-border-radius: $border-rad5;
  border: 1px solid #dddddd;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;

  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:hover,
  &:focus {
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
    border: 1px solid $primary-color;
  }
}

/*--Shared popups css --*/
.code_snippet_wrapper {
  position: absolute;
  width: 500px;
  max-height: 450px;
  border-radius: 10px;
  z-index: 100;
  overflow: auto;
  padding: 10px;
  background-color: #f8f8fe;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  pre {
    padding: -10px;
  }

  .snippet_tools {
    height: 30px;
    display: flex;
    justify-content: space-between;
    .snippet_icns {
      svg {
        margin: 2px;
      }
    }
    .snippet_languages {
      display: flex;
      div {
        margin: 0px 5px;
        cursor: pointer;
      }
    }
  }
}

.delete_modal {

  .modal-dialog {
    background: none;
    .modal-content {
      border-radius: 20px;
    }
  }
  .delete_modal_wrapper {
    padding: 25px;

    .delete_modal_header {
      display: flex;
      justify-content: space-between;
      svg {
        color: $muted-gray;
        cursor: pointer;
      }
    }

    .delete_text {
      color: $muted-gray;
      margin: 60px 0px;
      text-align: left;
    }

    .delete_btns {
      display: flex;
      justify-content: space-between;
      margin: 50px 0px;
      .btn {
        width: 220px;
        font-size: 14px;
        padding: 12px 24px;
        margin: 0px;
      }
    }
  }
}

.access_url_link {
  margin: 0px 10px;
  cursor: pointer;
  &.active {
    font-weight: bold;
  }
}

.filter_container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-top: 30px;
}

.search_container {
  display: flex;
  align-items: center;
  gap: 18px;
  padding-left: 5px;

  border-radius: 10px;
  background: #fff;
}

.lds_modal {
  margin-top: 5%;
}

//ADMIN PORTAL//
.admin_portal_wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 6.875rem;

  .admin_nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    overflow-y: auto;
    padding-top: 3.5rem;
    padding-left: 8.75rem;
    padding-right: 8.75rem;

    .heading {
      font-size: 32px;
      font-family: "Exo 2", sans-serif;
      font-weight: 700;
      color: #272845;
      margin-bottom: 3.5rem;
    }

    .controls {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 2.75rem;
      max-height: 2.75rem;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      .tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .tab {
          color: #646372;
          font-size: 16px;
          font-family: Lato, sans-serif;
          font-weight: 500;
          padding-left: 0;
          padding-right: 0;
          margin-right: 5rem;
          align-self: start;
          text-transform: none;
        }

        .Mui-selected {
          border-bottom: 1px solid;
          color: #272845;
          font-weight: 700;
        }
      }

      .admin_buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 2rem;

        .add_organization {
          font-size: 12px;
          margin-right: 0;
          width: 10rem;
        }

        .add_user {
          font-size: 12px;
          margin-right: 0;
          width: 8rem;
        }
      }
    }
  }
}

.admin_tab_panel {
  display: flex;
  flex-direction: column;
  padding-left: 8.75rem;
  padding-right: 8.75rem;
}

.admin_portal_details_panel {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-left: 8.75rem;
  padding-right: 8.75rem;
}

.org_panel_header {
  color: #646372;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.active_user_header {
  color: #646372;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 2.5rem;
}

.active_user_count {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;

  .count {
    color: #272845;
    font-family: "Lato", sans-serif;
    font-size: 26px;
    font-weight: 500;
  }

  .max_seats {
    color: #646372;
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-weight: 400;
  }

  .count_text {
    margin-left: 16px;
    color: #646372;
    font-size: 16px;
    align-self: flex-end;
  }
}

.admin_tab_panel_container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.app_status_details_container {
  margin-top: 2rem;
}

.panel_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  gap: 2rem;
  height: 30vh;
}

.carousel_panel_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  gap: 2rem;
  max-height: 40vh;
}

.panel {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;

  & .graph {
    width: 10vw;
    height: 10vh;
  }
}

.panel_horizontal {
  display: flex;
  flex-direction: column;
  width: 70vw;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  flex: 1 1 auto;

  .panel_horizontal_graph {
    display: flex;
    flex-direction: row;

    .panel_info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 200px;
    }

    .panel_horizontal_graph_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 3 3 auto;
      margin-left: 20px;
      min-width: 0;

      .overview_user_activities_graph {
        height: 100%;
        max-width: 60vw;
      }

      .overview_insights_usage_graph {
        height: 100%;
        max-width: 60vw;
      }
    }
  }
}

.entity_usage_graph_container {
  display: flex;
  width: 35vw;
  padding: 20px;

  .entity_usage_graph {
    height: 30vh;
  }
}

.panel_small {
  width: 25%;
}

.org_panel {
  min-width: 350px;
}

.user_panel {
  min-width: 350px;
}

.organization_panel_info {
  display: flex;
  flex-direction: column;
  height: 30vh;
  overflow-y: auto;
}

.organizations_table {
  .org_table_header {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    color: #646372;
  }

  .org_table_datum {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    color: #272845;
  }

  .column_centered {
    text-align: center;
  }
}

.user_activities_graph_container {
  height: 65vh;
  width: 70vw;
}

.entity_usage_overview_graph_container {
  align-self: center;
  width: 550px;
  height: 28vh;
}

.entity_usage_details_graph_container {
  display: flex;
  flex-direction: column;
  height: 65vh;
  width: 72vw;
  align-items: center;
  padding: 10px;
}

.iframe-container2 {
  position: relative;
  overflow: visible;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f6fa;
}

/* Fullscreen styles */
.iframe-container2.fullscreen {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f6fa;
}

/* Default iframe styles */
.iframe-container2 iframe {
  width: 100%;
  height: 94vh;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
}

/* Fullscreen iframe styles */
.iframe-container2.fullscreen iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
}

.fullscreen-toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 5px;
}



.panel_graph {
  display: flex;
  flex-direction: row;
  width: 550px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  max-width: 1650px;
  max-height: 1075px;

  &.align_items_end {
    align-items: flex-end;
  }
}


.chat_interface_wrapper .rcw-widget-container {
  position: static !important;
  width: 100% !important;
  height: calc(100% - 45px) !important;
  box-shadow: none;
  margin: 0;
  z-index: 0;
}

.dev-tools-icon {
  padding-bottom: 5px;
}

.clear-conversation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.clear-conversation {
  color: white;
  font-size: 14px;
}

.clear-conversation-icon {
  margin: 5px !important;
}

.left-group {
  display: flex;
}
.rcw-widget-container > .rcw-conversation-container {
  height: 100%;
  width: 100%;
  border-radius: 0;
}

.chat_interface_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ai-icon {
  margin:10px;
}

.ai-icon-button .ai-icon {
  margin: 5px 0 10px 0px;
}

.ai-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #57A5B3, #6B71FB);
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  transition: background 0.3s ease;
  height: 35px;
  margin-right: 10px;
}

.ai-icon-button:hover {
  background: linear-gradient(45deg, #5096a2, #5f63db);
}

.ai-icon-button .button-text {
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.rcw-launcher {
  display:none;
}

.rcw-conversation-container > .rcw-sender {
  align-items: center;
  width: 100%;
  border-radius: 0 !important;
  max-height: 60px;
}

.rcw-message {
  border-radius: 8px;
  padding: 8px 12px;
}

.rcw-response > .rcw-message-text {
  min-width: 400px !important;
}

.rcw-picker-btn {
  display: none;
}

.rcw-sender .rcw-new-message {
  width: 100%;
}

.rcw-message > .rcw-client > .rcw-message-text {
  background-color: #E9ECF6;
  max-width: 350px;
}

.rcw-message > .rcw-client > .rcw-message-text > p {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #4C5BAB;
  line-height: 1.5;
}

.rcw-timestamp {
  display: none;
}

.rcw-message-user {
  background-color: #3f51b5;
  color: #fff;
}

.rcw-avatar {
  margin-top: 10px;
}

.chat-visible {
  display: block;
}

.chat-hidden {
  display: none;
}


.rcw-message-participant {
  background-color: #f5f5f5;
  color: #333;
}

.rcw-response {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.rcw-message > .rcw-response > .rcw-message-text {
  background: #FFFFFF;
}

.rcw-message > .rcw-response > .rcw-message-text > p {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #646372;
  line-height: 1.5;
}

.rcw-message > .rcw-response > .rcw-message-text > ol > li {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #646372;
  line-height: 1.5;
}

.rcw-message > .rcw-response > .rcw-message-text > ol > li > p {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #646372;
  line-height: 1.5;
}

.rcw-response.pre {
  width: 100%;
}

body .rcw-conversation-container > .rcw-header {
  display: none;
}

.rcw-conversation-container .rcw-title {
  color: #fff;
}

.rcw-conversation-container .rcw-messages-container {
  height: calc(100% - 60px);
  background: #F9F9F9;
  max-height: none;
  overflow-y: auto;
}

.rcw-sender > .rcw-new-message {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #767EAA;
  padding-left: 10px;
  padding-right: 10px;
  width: 94%;
  background: #E9ECF6;
}

.chat-code-block {
  display: flex;
  flex-direction: column;
  width: 100%;

  .chat-code-block-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-color: #ECF4FF;
    padding: 0 6px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .code-block-language {
      font-size: 14px;
      font-family: "Lato", sans-serif;
      color: #338BFF;
    }

    .code-copy-button {
      align-self: flex-end;
      font-size: 14px;
      font-family: "Lato", sans-serif;
      color: #338BFF;
      border: 0;
      background: #ECF4FF;

      .copy-icon {
        font-size: 14px;
        margin-right: 3px;
      }

      .copy-button-text {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.align_items_end {
  align-items: flex-end;
}

.panel_header_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.expanded {
    width: inherit;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .time_range_expand_icon_group {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiIconButton-root {
      padding: 0;
    }
  }
}

.user_table_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -18px;

  .legacy_mode_button {
    margin: 0;
    font-size: 12px;
  }
}

.panel_header {
  color: #272845;
  font-size: 24px;
  font-family: "Exo 2", sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
}

.graph_row {
  display: flex;
  flex-direction: row;
  column-gap: 5vw;
  margin-bottom: 5vh;
}

.view_details {
  color: #6b71fb;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

.org_founding_date {
  color: #272845;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  margin-left: 20px;
}

.org_admin_email {
  color: #272845;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  margin-left: 20px;
}

.panel_description_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.panel_description {
  color: #646372;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-right: 20px;

  &.no_margin {
    margin-bottom: 0;
  }
}

.modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 1rem 2rem;

  .select_org_users_header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiIconButton-root {
      padding-left: 0;
    }
  }

  .header_text {
    font-size: 24px;
    font-weight: 700;
    font-family: "Exo 2", sans-serif;
  }
}

.field_wrapper {
  font-size: 12px;
  width: 100%;
  margin-bottom: 1rem;

  .add_user_password_field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
    border-top: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 8px;

    .add_user_password_field_input {
      width: inherit;
      padding: 0.75rem;
      font-size: 16px;
      height: 40px;
      border-left: 1px solid #e2e2e2;
      border-radius: 8px;
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
    }

    .password_field_eye_icon {
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }
}

//overrules canvas's tendency to not pick
//up the right height and width when resizing
.admin_portal_graph {
  height: 60vh !important;
  width: 70vw !important;
  padding-bottom: 2rem;
}

.insights_usage_overview_graph {
  max-height: 250px;
  max-width: 500px;
}

.add_org_modal_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  margin-right: 3.75rem;

  .MuiFormControl-root {
    margin: 0 0 1rem 1.5rem;

    .MuiFormControlLabel-root {
      margin-bottom: 1rem;

      .MuiFormControlLabel-label {
        margin-left: 0.5rem;
        font-size: 14px;
        font-family: "Lato", sans-serif;
      }
    }
  }

  .add_org_save_button_container {
    align-self: center;
    margin-top: 1.75rem;

    .add_org_save_button {
      align-self: center;
      margin: 0;
      width: 100px;
    }
  }
}

.flighting_config_modal {
  margin-top: 25px;
}

.flighting_config_modal_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  margin-right: 3.75rem;

  .MuiFormControl-root {
    margin: 0 0 1rem 0.6rem;

    .MuiFormControlLabel-root {
      margin-bottom: 1rem;

      .MuiFormControlLabel-label {
        margin-left: 0.5rem;
        font-size: 14px;
        font-family: "Lato", sans-serif;
      }
    }
  }
}

.flighting_config_select {
  width: 100%;
  margin-bottom: 0.5rem;
}

//ADMIN PORTAL ADD USER MODAL//
.add_user_modal_body {
  display: flex;
  flex-direction: column;
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.add_user_field_header {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.add_user_field {
  width: 100%;
  height: 40px;
  margin-bottom: 0.5rem;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 0.75rem;
}

.add_user_select {
  width: 100%;
  margin-bottom: 0.5rem;
}

.add_user_save_button_container {
  align-self: center;
  margin-top: 1.75rem;

  .add_user_save_button {
    align-self: center;
    margin: 0;
    width: 100px;
  }
}

//ADMIN PORTAL EDIT USER MODAL//
.edit_field_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 1rem;

  .edit_field_label {
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    color: #8384a1;
    width: 100px;
  }

  .edit_user_password_field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 225px;
    margin-left: 1rem;
    border-top: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 8px;

    .edit_user_password_field_input {
      padding: 0.75rem;
      font-size: 16px;
      height: 40px;
      border-left: 1px solid #e2e2e2;
      border-radius: 8px;
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
    }

    .password_field_eye_icon {
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }

  .edit_field {
    width: 225px;
    height: 40px;
    margin-left: 1rem;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    padding: 0.75rem;
    font-size: 16px;
  }

  .edit_select {
    width: 225px;
    margin-left: 1rem;
    font-size: 16px;
  }
}

.edit_user_button_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: space-between;

  .edit_user_delete_button {
    background: #ffffff;
    color: #6b71fb;
    border: 1px solid #ffffff;
    margin-left: 0;
    padding-left: 14px;
  }
}

//TIME RANGE SELECTOR//
.time_range_button_group {
  background-color: #dbeaff;
  height: 1.5rem;
  margin-bottom: 1rem;
  width: 168px;
  margin-right: 20px;

  &.align_self_end {
    align-self: end;
  }

  &.no_margin {
    margin-bottom: 0;
  }

  .time_range_button {
    width: 42px;
    border: 0;

    .time_range_button_text {
      color: #338bff;
      font-family: "Lato", sans-serif;
      font-size: 14px;
    }

    &.Mui-selected {
      background-color: #338bff;

      .time_range_button_text {
        color: #ffffff;
      }

      &:hover {
        background-color: #338bff;
      }
    }

    &:hover {
      background-color: #dbeaff;
    }
  }
}

//CAROUSEL//
.carousel {
  display: flex;
  flex-direction: column;

  .carousel_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    .carousel_controls {
      display: flex;
      flex-direction: row;
      width: 6rem;
      align-items: center;
      justify-content: space-between;

      .arrow {
        background-color: #ffffff;
        border: 2px solid #e2e2e2;
        border-radius: 8px;
        color: #646372;

        &.arrow_disabled {
          background-color: transparent;
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }
}

.org_tiles_row {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.org_card_top_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .org_card_header {
    font-family: "Lato", sans-serif;
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.menu_button {
  svg {
    color: #cccccc;
  }
}

.num_active_users {
  display: flex;
  flex-direction: row;
  align-items: center;

  .users_licenses_ratio {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: #646372;
  }
}

//EMPTY GRAPH//
.empty_graph_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.empty_graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .empty_graph_text {
    font-size: 20px;
    font-family: "Lato", sans-serif;
    color: #8384a1;
  }
}

//DOCKER TERMINAL//
.terminal_ref {
  height: 100%;
}

//VISUAL WORKFLOWS//
.project-link {
  display: flex;
  flex-direction: column;
  border-left: 2px solid rgb(210, 210, 210);
  border-top: 2px solid rgb(210, 210, 210);
  background: white;
  height: 30%;
  padding-left: 15px;
  padding-top: 5px;
  align-items: baseline;

  .navlink-active {
    color: #6B71FB;
  }

  .navlink-inactive {
    color: gray;
    pointer-events: none;
  }

  .project-link-icon {
    font-size: 16px;
    color: #888;
    margin-top: 6px;
  }
  .project-link-name {
    gap: 10px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: bold;
    margin: 0;

    .label {
      color: #888;
    }

    .value {
      opacity: .6;
    }
  }
}

.email-menu-container {
  position: relative;
  display: inline-block;
}

.email-notification {
  display: flex;
}

.email-row {
  display: flex;
  padding: 5px 0;
  justify-content: space-between;
}

.email-add-icon{
  display: flex;
  margin-top: 4px;
  margin-left: 4.5px;
}

.email-notification-text {
  cursor: pointer;
  color: $primary-color;
  padding-top: 8px;
  padding-left: 4px;
}

.email-notification-text:hover {
  color: $primary-color-light;
  text-decoration: underline;
}

.workflow_details_header {
  display: flex;
  flex-direction: column;

  svg {
    color: $primary-color;
  }

  #calendar_icon {
    cursor: pointer;
    font-size: 20px;
    height: 40px;
    width: 40px;
    margin-top: 5px;
  }

  .top_row {
    padding: 25px;
    display: flex;

    .top_row_right {
      margin-right: 0;
      margin-left: auto;
      display: flex;
      gap: 8px;

      #white_btn {
        background: white;
        color: $primary-color;
      }
    }
  }
}

.visual_workflow_header {
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  background-image: linear-gradient(#eff2ff, #ffffff);
  height: 151px;

  svg {
    color: $primary-color;
  }


  #calendar_icon {
    cursor: pointer;
    font-size: 20px;
    height: 40px;
    width: 40px;
    margin-top: 5px;
  }

  #calendar_icon_inactive {
    font-size: 20px;
    height: 40px;
    width: 40px;
    margin-top: 5px;
  }

  #calendar_icon_inactive {
    font-size: 20px;
    height: 40px;
    width: 40px;
    margin-top: 5px;
  }

  .top_row {
    padding-top: 28px;
    padding-left: 28px;
    padding-right: 28px;
    display: flex;

    .top_row_right {
      margin-right: 0;
      margin-left: auto;
      display: flex;
      gap: 7px;
      align-items: center;

      #white_btn_long {
        padding: 0;
        background: white;
        color: $primary-color;
        width: 156px;
        height: 44px;
      }

      #white_btn_short {
        padding: 0;
        background: white;
        color: $primary-color;
        width: 44px;
        height: 44px;
      }

      #purple_btn {
        padding: 0;
        width: 104px;
        height: 44px;
      }

    }
  }
}
.workflow_breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 25px;

  .back_link {
    display: flex;
    flex-direction: row;
    align-items: center;

    .no_margin_left {
      margin-left: 0;
    }
  }

  svg {
    margin: 5px;
    font-size: 10px;
  }

  .list_bread_crumb {
    color: $muted-primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .detail_bread_crumb {
    color: $primary-color;
    font-weight: bold;
  }

  .title_bread_crumb {
    color: $primary-color;
    font-style: italic;
  }
}

.bottom_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  padding-left: 45px;
  margin-bottom: 15px;
  margin-top: auto;

  .test_run {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: #6b71fb;
    opacity: 0.5;
    padding: '10px';
    border-radius: '5px';

    .circle_style {
      position: relative;
      width: 20px;
      height: 20px;
      background: transparent;
      cursor: pointer;
      border: 2px solid $primary-color;
      border-radius: 50%;
      align-self: center;

      .arrow_style {
        position: absolute;
        top: 50%;
        left: 53%;
        transform: translate(-50%, -50%) rotate(-90deg);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid $primary-color;
      }
    }
  }

  .email-notification-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    gap: 10px;

    label {
      font-size: 16px;
      color: #595959;
      margin: 0;
    }

    input[type='checkbox'] {
      appearance: none;
      width: 24px;
      height: 24px;
      background-color: #e0e0e0;
      border-radius: 4px;
      border: 2px solid #7a7a7a;
      margin-right: 12px;
      position: relative;
    }

    input[type='checkbox']:checked {
      background-color: #6e7ef1;
      border-color: #6e7ef1;
    }

    input[type='checkbox']:checked::after {
      content: '✔';
      color: white;
      font-size: 16px;
      position: absolute;
      left: 4px;
      top: 0;
    }
  }

  .email-menu-close-button {
    margin-top: 10px;
    padding: 8px;
    cursor: pointer;
    background-color: #ccc;
    border: none;
  }

  .toggle-item {
    display: flex;
    align-items: center;
  }

  .email-notification-header {
    font-size: 14px;
    color: #595959 ;
  }




  .email-menu-list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    z-index: 1000;
    width: 250px;
    border-radius: 15px;
  }

  .zoom {
    color: $muted-primary;
    display: flex;
    flex-direction: row;
    gap: 1px;

    .zoom_value {
      width: 50px;
      text-align: center;
    }

    .zoom_adjust {
      cursor: pointer;
    }
  }
}

.btn_small {
  .organize_btn {
    display: flex;
    flex-direction: row;
    gap: 5px;
    svg {
      color: white;
    }
  }

  .trigger_dag {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .circle_style {
      position: relative;
      width: 20px;
      height: 20px;
      background: transparent;
      cursor: pointer;
      border: 2px solid white;
      border-radius: 50%;
      align-self: center;

      .arrow_style {
        position: absolute;
        top: 50%;
        left: 53%;
        transform: translate(-50%, -50%) rotate(-90deg);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid white;
      }
    }
  }
  &:hover {
    .organize_btn {
      svg {
        color: $primary-color;
      }
    }
    .circle_style {
      border: 2px solid $primary-color;
      .arrow_style {
        border-top: 7px solid $primary-color;
      }
    }
  }
}

.circle_instruction_style {
  position: relative;
  min-width: 30px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  border: 2px solid $primary-color;
  border-radius: 50%;
  align-self: center;
  text-align: center;
}

.visual_workflow {
  display: flex;
  flex-direction: column;

  .workflow_designer_wrapper {
    width: 75vw;
    height: 100vh;
  }
  .workflow_designer_container {
    border-top: 2px solid rgb(210, 210, 210);
    display: flex;
  }
  .workflow_designer_wrapper {
    flex: 1;
  }
  .visual_workflow_config_wrapper {
    width: 350px;
  }
}

.visual_workflow_config_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .configuration {
    border-left: 2px solid rgb(210, 210, 210);
    background: white;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 70%;
    width: 100%;
    overflow: auto;
    .header {
      display: flex;
      flex-direction: row;
      gap: 10px;
      color: #5e6ec3;
      margin: 10px 0;
      font-size: 20px;
      .delete {
        cursor: pointer;
        display: flex;
        margin-right: 65px;
        margin-left: auto;
        align-items: center;
        font-size: medium;
      }
    }
    .content {
      height: 100%;
      display: flex;
      flex-direction: row;
      .options {
        display: flex;
        flex-direction: column;
        width: 80%;
        gap: 10px;

        .MuiFormControl-root {
          width: 100%;
        }

        & .MuiInputBase-root {
          height: 40px;
        }

        .MuiAutocomplete-input {
          height: auto;
          padding-top: 0;
        }
      }
    }
  }
}
.required-field::before {
  content: "*";
  color: red;
}
.instruction {
  background: white;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 20px;
  width: 30%;
  .header {
    color: #5e6ec3;
  }
  ul {
    gap: 5px;
    display: flex;
    flex-direction: column;
    padding-top: inherit;
    li {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}

//** SHARED COMPONENTS ** //

.back_btn {
  rotate: 90deg;
  font-size: 35px !important;
  border-radius: 50%;
  color: $primary-color;
  cursor: pointer;
}

.back_link {
  display: flex;
  align-items: center;
}

.lds_breadcrumb {
  display: flex;
  align-items: center;
  font-size: 14pt;

  .list_breadcrumb {
    color: $muted-primary;
    cursor: pointer;
    text-transform: capitalize;
    margin-left: 3px;
    &:hover {
      text-decoration: underline;
    }
  }

  .details_breadcrumb {
    color: $primary-blue;
    font-weight: bold;
  }

  .arrow_forward {
    margin: 5px;
    font-size: 10px !important;
    color: $primary-color;
  }
  p {
    font-size: 35px;
  }
}
.project_datasets_list {

  margin-left: 5%;
  .button_group {
    display: flex;
    flex-direction: row;
    color: #4c5bab;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1%;
    border-radius: 5px;
    gap: 2%;
    .my_files {
      font-size: 11pt;
      width: 49%;
      border-radius: 5px;
      border-right: 0px;
      text-align: center;
      &:hover {
        background-color: #ffffff;
        cursor: pointer;
      }
    }

    .shared {
      font-size: 11pt;
      width: 49%;
      border-radius: 5px;
      border-right: 0px;
      text-align: center;
      &:hover {
        background-color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

.action-card-container {
  align-self: flex-start;
  position: sticky;
  top: 0;
}

// Shows the scrollbar for mac OS if one is present
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.lds_workflow_modal {
  button {
    float: right;
  }

  h6 {
    margin-top: 30px;
  }

  p {
    color: $gray-medium;
  }

  .manual-workflow-link {
    color: $primary-color;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

// TWO FACTOR AUTH VERIFY MODAL
.two_factor_auth_verify_modal {
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  .two_factor_auth_verify_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.25rem 1rem 2.25rem;

    .header_text {
      font-size: 24px;
      font-weight: 700;
      font-family: "Exo 2", sans-serif;
    }
  }

  .two_factor_auth_verify_body {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .two_factor_auth_verify_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    .two_factor_auth_verify_back_link {
      display: flex;
      flex-direction: row;
      align-items: center;

      .back_text {
        margin-left: 2px;
        font-size: 16px;
      }
    }

    .two_factor_auth_verify_done_btn {
      margin-top: 10px;
    }
  }
}

.two_fa_verified {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  .check_circle {
    color: #4EBE59;
    margin-right: 5px;
  }

  .active {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    color: #4EBE59;
  }
}

.two_factor_user_status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 1rem;
}

.enable_two_fa_link {
  cursor: pointer;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  color: #6B71FB;
  width: fit-content;
}

.submit_button {
  margin-left: 0;
  margin-right: 0;
}

// TWO FACTOR AUTH ENABLE MODAL
.two_factor_auth_enable_modal {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;

  .two_factor_auth_enable_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.25rem 1rem 2.25rem;

    .header_text {
      font-size: 24px;
      font-weight: 700;
      font-family: "Exo 2", sans-serif;
    }
  }

  .two_factor_auth_enable_body {
    display: flex;
    flex-direction: column;
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    .authenticate_note {
      font-family: "Lato", sans-serif;
      color: #646372;
      margin-bottom: 10px;
    }

    .two_factor_instruction_card {
      box-shadow: none;

      &.expanded {
        background-color: #F9F9F9 !important;
        padding-left: 16px;
        border-radius: 8px;
      }

      .verification_instructions {
        padding-left: 0;

        .show_hide_instruction {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;
          font-family: "Lato", sans-serif;
          color: #338BFF;
          cursor: pointer;

          .instruction_icon {
            margin-right: 5px;
          }
        }
      }

      .instruction_container {
        padding-left: 0;
      }

      .step {
        display: flex;
        flex-direction: row;
      }

      .step_circle_container {
        width: 30px;

        .step_circle {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          background-color: #D9EDFF;
          border-radius: 50%;
          margin-top: 3px;
          margin-right: 10px;

          .step_circle_text {
            color: #338BFF;
          }
        }
      }

      .step_text {
        font-size: 14px;
        font-family: "Lato", sans-serif;
        color: #646372;
      }
    }

    .secret_label {
      font-size: 1rem;
      font-family: "Lato", sans-serif;
      color: #646372;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .secret_code_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;

      .lds_login_form_input {
        width: 100%;
      }

      .secret_code {
        padding: 0.75rem;
        font-size: 16px;
        height: 40px;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        width: 100%;
        background-color: #FFFFFF;
      }

      .password_field_eye_icon {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        cursor: pointer;
        justify-self: end;
      }
    }

    .secret_qr_container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .MuiPaper-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
      }
    }

    .six_digit_code_label {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #646372;
    }

    .six_digit_code_input {
      font-family: "Lato", sans-serif;
      width: 100%;
      height: 40px;
      margin-bottom: 0.5rem;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      padding: 0.75rem;

      &:after {
        border-bottom: 0;
      }
    }
  }

  .two_factor_auth_enable_footer {
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    .verify_btn {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// ADMIN PORTAL SECURITY TAB
.security_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .security_org_dropdown {
    display: flex;
    flex-direction: column;
  }
}

.security_title {
  margin-bottom: 0;
}

.security_org_selector {
  width: 30vw;
}

.mx-2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-icon {
  color:$gray-medium;
  size:100px;
  cursor:default;
  &:hover {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0));
  }
}

.infinite_scroll_container {
  height: 70vh;
  overflow: auto;
  margin-bottom: 10px;
}

.ludis_table {
  width: 100%;
  background-color: #FFFFFF;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #E7E7ED;
  border-radius: 8px;

  &.infinite_scroll {
    height: 100vh;
  }
}

.ludis_table_header_cell {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  padding: 16px 0 16px 16px;
  color: #272833;
  border-bottom: 1px solid #E7E7ED;
}

.ludis_table_cell {
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 16px 16px 16px 16px;
  color: #646372;
  border-bottom: 1px solid #E7E7ED;

  &.center {
    text-align: center;
    vertical-align: center;
  }
}

.ludis_table_row {
  border-top: 1px solid #E7E7ED;
  border-bottom: 1px solid #E7E7ED;
}

.modal-prompts {
  text-align: left;
  margin-left: 20px;
}

.modal-header-text {
  font-size: 24px;
  color: $black;
  font-family:"Exo 2";
  font-weight: 700;
  text-transform: capitalize;
  line-height: 28px;
}

.body-color {
  color:#646372
}

.scroll-button {
  position: fixed;
    bottom: 10px;
    right: 10px;
    width: 42px;
    height: 42px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 60px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    opacity: 0.3;
}

.scroll-button:hover {
  background-color:#305ed2;
  opacity: 0.9;
}

.metrics_search_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 1px solid #DDDDDD;
  border-top: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius: 15px;
  -khtml-border-radius: 15px;
  margin-top: 36px;
  margin-bottom: 36px;
  padding-left: 4px;
  background: #FFFFFF;
  width: 20vw;
}

.metrics_keyword_input {
  width: 100%;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #646372;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}