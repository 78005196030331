.user_pagination,
.page_link {
  color: #9b51e0 !important;
}

.dsahboard_Area {
  float: left;
  width: 100%;
  padding: 30px 0;
}

.card_medium {
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  display: block;
  max-width: 1200px;
  width: 100%;
  margin: 0;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
}

.card_large {
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  display: block;
  max-width: 1200px;
  width: 115%;
  margin: 0;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
}

.Table {
  width: 100%;
  border-spacing: 0;
}

.db_ListViewItem_header {
  background-color: #f7fafc;
  color: #4f566b;
  height: 100%;
}

.UserTable_header {
  background-color: #FFFFFF;
  color: #4F566B;
  height: 40px;
}

.header_cell {
  padding: 10px;
}

.Table_cell {
  white-space: nowrap;
  width: 1px;
  vertical-align: top;
  text-align: left;
  box-shadow: inset 0 -1px #e3e8ee;
  color: #2a2f45;
  line-height: 20px;
  font-size: 1rem;
  font-weight: 600;
}

.db_ListViewItem_hasLink {
  cursor: pointer;
}

.db_ListViewItem_hasLink:hover {
  background-color: #f7fafc;
}

.db_ListViewItem {
  color: #4f566b;
  height: 100%;
}

.th_block {
  padding: 15px;
}

.tr_td {
  padding: 8px;
}

.Table_cell_I_inn {
  color: #2a2f45;
  padding: 10px;
  line-height: 20px;
  font-size: 1rem;
  font-weight: 400;
  box-shadow: inset 0 -1px #e3e8ee;
}

.Divider_light_bottom {
  padding: 10px 10px;
  box-shadow: inset 0 -1px #e3e8ee;
}

.Box_root {
  display: inline-block;
  width: 100%;
}

.left_Side {
  float: left;
}

.center {
  text-align: center;
}

.right_Side {
  float: right;
}

.button_Click {
  color: #4f566b;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(42, 47, 69, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(42, 47, 69, 0.12) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 8px;
  min-width: 100px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
  text-shadow: none;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.button_Click:hover,
.button_Click:focus {
  background-color: rgb(247, 250, 252);
}

.result_All {
  font-size: 12.5px;
  margin-top: 5px;
  float: left;
}

.result_All strong {
  font-size: 14px;
}

.Next_btn {
  margin-left: 8px;
}

.Button_disabled {
  color: #a3acb9;
  background-color: rgb(247, 250, 252);
}

.pl_10 {
  padding-left: 10px;
}

.pr_10 {
  padding-right: 10px;
}

.pl_20 {
  padding-left: 20px;
}

.pr_20 {
  padding-right: 17px;
}

.Td_Btn {
  background: #d6ecff;
  color: #3d4eac;
  display: inline-block;
  margin-left: 15px;
  padding: 2px 10px;
  border-radius: 100px;
  text-decoration: none;
  min-width: 100px;
  text-align: center;
  font-weight: 600;
}

.Td_Btn:hover {
  color: #556cd6;
}

.btn_transperent {
  background: none;
  border: none;
}

.color_primary {
  color: #9b51e0;
}

.ldsToolTips {
  padding-right: 0px;
}

.collection_list {
  color: #2a2f45;
  padding: 10px;
  line-height: 20px;
  font-size: 1rem;
  font-weight: 400;
  box-shadow: inset 0 -1px #e3e8ee;
  padding-left: 10px;
  padding-right: 10px;
  padding: 15px;
}