/*
  Flaticon icon font: Flaticon
  Creation date: 15/04/2020 05:49
  */

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Flaticon.woff2") format("woff2"),
    url("../fonts/Flaticon.woff") format("woff"),
    url("../fonts/Flaticon.ttf") format("truetype"),
    url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: "Flaticon";
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.flaticon-home:before {
  content: "\f100";
}

.flaticon-pie-chart:before {
  content: "\f101";
}

.flaticon-smartphone:before {
  content: "\f102";
}

.flaticon-house:before {
  content: "\f103";
}

.flaticon-notification:before {
  content: "\f104";
}

.flaticon-question:before {
  content: "\f105";
}

.flaticon-conversation:before {
  content: "\f106";
}

.flaticon-calendar:before {
  content: "\f107";
}

.flaticon-file:before {
  content: "\f108";
}

.flaticon-server:before {
  content: "\f109";
}

.flaticon-share:before {
  content: "\f10a";
}

.flaticon-magnifying-glass:before {
  content: "\f10b";
  font-size: 14px;
}

.flaticon-conversation-1:before {
  content: "\f10c";
}

.flaticon-bar-chart:before {
  content: "\f10d";
}

.flaticon-pen:before {
  content: "\f10e";
}

.flaticon-price-tag:before {
  content: "\f10f";
}

.flaticon-text:before {
  content: "\f110";
}

.flaticon-squares:before {
  content: "\f111";
}

.flaticon-play-arrow:before {
  content: "\f112";
}

.flaticon-left-arrow:before {
  content: "\f113";
}

.flaticon-stop:before {
  content: "\f114";
}

.flaticon-down-arrow:before {
  content: "\f115";
}

.flaticon-lighting:before {
  content: "\f116";
}