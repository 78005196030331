#forecastLegendID ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    border-radius: 10px;
    opacity: 0.8;
    background: #FFF;
    /* shadow 2 */
    box-shadow: 10px 10px 40px 0px rgba(180, 180, 224, 0.20);
}

#forecastLegendID ul li {
    cursor: pointer;
    display: flex;
    align-items: left;
    flex-direction: row;
    margin-bottom: 5px;
    margin-right: 5px;
    /* avoid click bug*/
    line-height: 26px;
    align-self: left;
    padding-left: 16px;
}

#forecastLegendID ul li span {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    align-self: center;
}

#forecastLegendID ul li input {
    display: inline-block;
    height: 26px;
    width: 26px;
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
}

#forecastLegendID ul li p {
    margin: 0;
    padding: 0;
    color: rgba(102, 102, 102, 1);
}

#forecastLegendID ul li.fade2 p {
    color: rgba(102, 102, 102, .5);
}

#forecastLegendID ul h5 {
    align-self: left;
    padding-bottom: 5px;
    color: #000;
    padding-left: 16px;

    /* text-base 16px */
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

#clusterLegend {
    overflow: scroll;
    height: 200px;
}

#clusterLegend ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    border-radius: 10px;
    opacity: 0.8;
    background: #FFF;
    /* shadow 2 */
    box-shadow: 10px 10px 40px 0px rgba(180, 180, 224, 0.20);
}

#clusterLegend ul li {
    cursor: pointer;
    display: flex;
    align-items: left;
    flex-direction: row;
    margin-bottom: 5px;
    margin-right: 5px;
    /* avoid click bug*/
    line-height: 26px;
    align-self: left;
    padding-left: 16px;
}

#clusterLegend ul li span {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    align-self: center;
    margin: 8px;
}

#forecastLegendID ul li input {
    display: inline-block;
    height: 26px;
    width: 26px;
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
}

#clusterLegend ul li p {
    margin: 0;
    padding: 0;
    color: rgba(102, 102, 102, 1);
}

#clusterLegend ul li.fade2 p {
    color: rgba(102, 102, 102, .5);
}

#clusterLegend ul h5 {
    align-self: left;
    padding-bottom: 5px;
    color: #000;
    padding-left: 16px;
    font-size: 24px;

    /* text-base 16px */
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

.campaignContainer {
    margin-top: 100px;
}

.optAnalysis {
    display: flex;
    height: 1534px;
    flex-direction: row;
    align-items: flex-start;
    background: #F9F9FA;
}

.optAnalysisSidebar {
    display: flex;
    width: 24%;
    height: 1471px;
    flex-shrink: 0;
    flex-direction: column;
}

.optAnalysisMain {
    width: 75%;
    height: 1471px;
    flex-shrink: 0;
    background: #FFF;
}

.optAnalysisSummary {
    display: flex;
    gap:4%;
    align-items: center;
    height: 20%;

    background: #F9F9FA;
}

.optAnalysisForecast {
    display: flex;
    height: 40%;
    width: 100%;
    align-items: center;
    justify-content: center;

    border-bottom: solid;
    border-radius: 1px;
    border-color: #BFBFBF;
}

.chartBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 5%;
    margin-left: 1%;
}

.myChartCluster {
    display: flex;
    width: 60%;
    height: 90%;
}

.myChartClusterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 70%;
    padding-left: 16px
}

.myChartForecast {
    display: flex;
    width: 70%;
    height: 100%;
    padding-top: 16px;
    padding-left: 16px;
}

.optAnalysisCluster {
    height: 40%;
}

.chartBoxCluster {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 5%;
    margin-left: 1%;
}

.myInfo {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    gap: 10px;

    grid-column: 2 / 2;
    grid-row: 2 / 2;
}

.forecastLegend {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    margin-top: 20px;
    width: 20%;
}

.datapoint {
    background: #FFF;
    /* shadow 1 */
    box-shadow: 15px 15px 60px 0px rgba(197, 197, 219, 0.25);
}

.DataPointInfoBodyValue {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 3px;

    width: 15.6%;
    height: 284px;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-left: 5px;
}

.DataPointInfoBodyValueInside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    height: 284px;
    flex: none;
    order: 0;
    flex-grow: 0;
    gap: 16px;
}

#DataPointInfoHeader {
    display: flex;
    flex-direction: column;
    margin: 0;
    color: #000;

    /* text-base 16px */
    font-family: Lato;
    font-size: 24px;
    padding-left: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    padding-bottom: 16px;
}

.datapoints {
    display: flex;
    width: 246px;
    align-items: center;
    gap: 42px;
    padding-left: 16px;
}

.datapointLabel {
    color: var(--Gray-1, #8B8CA9);

    /* text-xs 12px */
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 40%;
}

.datapointValue {
    color: var(--Text-color, #272845);

    /* text-xs 12px */
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 40%;
}

.summaryBox {
    display: flex;
    width: 33%;
    height: 128px;
    padding-left: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;

    background: var(--White, #FFF);
}

.summaryBoxTitle {
    height: 35px;
    padding-top: 5px;
    padding-right: 5px;

    color: var(--Gray-1, #8B8CA9);
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.summaryBoxBody {
    color: var(--Text-color, #272845);
    text-align: center;

    /* text-lg 20px */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
}

.summaryBoxFooter {
    display: flex;
    align-items: center;
    gap: 8px;
}

.percentChangePos {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: var(--Green-bg, #EFFFF1);
    color: var(--Green-Outline, #4EBE59);
    height: 20px;
}

.percentChangeNeg {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #FFF3F2;
    color: #F57269;
    height: 20px;
}

.percentChangeNeutral {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #FFF7E4;;
    color: #E5A80A;
    height: 20px;
}

.percentChangeNumber {
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
}

.percentChangeIcon {
    display: flex;
    width: 12px;
    height: 12px;
    justify-content: center;
    align-items: center;
}

.percentChangeLabel {
    color: var(--Gray-1, #8B8CA9);
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
}

.summaryBoxes {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.optAnalysisSummaryCurrent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 48%;
}

.optAnalysisSummaryCurrentTitle {
    width: 100%;
    color: var(--Text-color, #272845);
    padding: 6px 20px;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
}

.sidebarOptimizationMetric {
    display: inline-flex;
    flex-direction: column;
    align-items: left;
    gap: 40px;
    padding-left: 15px;
    width: 88%;
}

.sidebarOptimizationMetricContainer {
    width: 100%;
    height: 882.5px;

    border-radius: 1px;
    border-bottom: solid;
    border-color: #BFBFBF;
}


.sidebarOptimizationMetricHeader {
    color: var(--Text-color, #272845);
    font-family: Exo 2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
}

.sidebarOptimizationMetricBody {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    gap: 42px;
    height: 600px;
    width: 100%;
}

.sidebarOptimizationMetricYContainer {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    gap: 42px;
}

.sidebarCohortAnalysis {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 42px;
    padding-left: 15px;
    width: 88%;
}

.sidebarOptimizationMetricTitle {
    color: var(--Text-color, #272845);
    font-family: Exo 2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
}

.sidebarOptimizationMetricY {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.sidebarOptimizationMetricX {
    display: flex;
    align-items: flex-start;
    gap: 130px;
}

.sidebarOptimizationMetricSwitch {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 8px;
    align-items: center;
}

.sidebarOptimizationMetricSwitchContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-self: flex-start;
    padding-left: 15px;
}

.sidebarOptimizationMetricDropdown {
    width: 94%;
}

.sidebarOptimizationMetricTitle {
    color: var(--Text-color, #272845);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
}

.sidebarOptimizationMetricCaption {
    color: var(--Gray-2, #646372);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.css-1xc3v61-indicatorContainer {
    transform: rotate(-90deg);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.css-1u9des2-indicatorSeparator {
    background-color: #DFEDFF;
}

.css-1fdsijx-ValueContainer {
    width: 119px;
    flex-shrink: 0;

    color: var(--Gray-2, #646372);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.08px;
}

.sidebarOptimizationMetricSwitchText {
    color: var(--Accent-color-1, #338BFF);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.2px;
}

.sidebarCohortAnalysisTitle {
    padding-top: 16px;
    color: var(--Text-color, #272845);
    font-family: Exo 2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
}

.sidebarCohortAnalysisCaption {
    color: var(--Gray-1, #8B8CA9);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.sidebarCohortAnalysisClusters {
    display: flex;
    width: 216px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding-right: 15px;
    width: 100%;
}

.sidebarCohortAnalysisClustersHeader {
    display: flex;
    width: 216px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-right: 15px;
    width: 100%;
}

.sidebarCohortAnalysisClustersTitle {
    color: var(--Text-color, #272845);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.sidebarCohortAnalysisClustersCaption {
    color: var(--Gray-1, #8B8CA9);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.sidebarCohortAnalysisOptions {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    background: #fff;
    padding-left: 15px;
    width: 100%;
}

.sidebarCohortAnalysisHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.waitingForDataContainer {
    align-self: center;
    border-radius: 8px;
    background: #EEF5FF;
    display: inline-flex;
    padding: 20px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.waitingForData {
    color: #8B8CA9;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.notebook-open-wrapper img {
    width: 50%;
    height: 50%;
    justify-self: center;
    align-self: center;
}

.optAnalysisSummaryForecastTitle {
    display: flex;
    padding: 6px 20px;
    align-items: center;
    gap: 16px;

    border-radius: 4px;
    background: #E9F0F9;

    color: #338BFF;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.optAnalysisSummaryForecastTitleGray {
    display: flex;
    padding: 6px 20px;
    align-items: center;
    gap: 16px;

    border-radius: 4px;
    background: #F0F0F0;

    color: #8D8D93;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.loading {
    color: var(--Gray-1, #8B8CA9);
}

.progress {
    width: 100%;
    align-self: center;
}

.spinner {
    height: 100%;
}