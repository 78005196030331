.axisDisplaySad {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 93%;
    height: 299px;
    margin-left: 1%;

    background: #F9FAFE;
    /* Stroke Color */

}

.box {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    gap: 15px;
    height: 300px;
    width: calc(100% / 3);
    background-color:#F4F5F9;
    border-right: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
}

.dropdownCustomSad {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    vertical-align: middle;
    width: 30%;
}

.axisDisplayXSad {
    width: 40%;
    height: 128px;
    left: 50%;
    top: 124px;
    margin: 10%;

    background: #FFFFFF;
    box-shadow: -10px -10px 30px rgba(255, 255, 255, 0.1), 10px 10px 40px rgba(180, 180, 224, 0.2);
    border-radius: 8px;
}

.sadModule {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 121px;
    height: 30px;
    outline-style: solid;
    cursor: pointer;
    outline-color: #7B81FF;
    background: #ECEDFF;
    border-radius: 4px;

    color: #7B81FF;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

.sadModuleHeader {
    outline-color: #7B81FF;
}

.graphTypes {
    height: 81%;
    width: 90%;
    border-radius: 10px;
    overflow-y:auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    align-items: center;
}

.graphPanel {
    display: flex;
    flex-direction: column;
    width: 288px;
    height: 60%;

    filter: drop-shadow(-10px -10px 30px rgba(255, 255, 255, 0.1)) drop-shadow(10px 10px 60px rgba(180, 180, 224, 0.15));
    border-radius: 10px;

    flex: none;
    flex-grow: 0;
}

.headingStyle {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-left: 1%;
    top: 10px;
}

.headingStyleAppInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.headingStyleDataInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.headingStyleChartInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.headingStyleTitle {
    color: var(--Text-color, #272845);
    font-family: Exo 2;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
}

.headingStyleDescription {
    color: var(--Text-color, #272845);

    /* text-base 16px */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

.DataInputTitle {
    color: var(--Text-color, #272845);
    font-family: Exo 2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
}

.DataInput {
    display: flex;
    padding: 10px;
    min-width: 15%;
    width: min-content;
    flex-direction: column;
    align-items: center;
    gap: 17px;

    border-radius: 10px;
    background: #FFF;

    /* shadow 2 */
    box-shadow: 10px 10px 40px 0px rgba(180, 180, 224, 0.20);
}

.graphTitle {
    width: 188px;
    height: 28.545px;

    color: var(--Text-color, #272845);
    font-family: Exo 2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
}

.dataInputImage {
    height: 15px;
    width: 30px;
}

.graphInfo {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.clusterModes {
    display: flex;
    flex-direction: row;
    gap: 5%;
    width: 100%;
}

.templateContainer {
    background:#EFF4FF;
    display: flex;
    gap: 2%;
    flex-direction: column;
    width:90%;
    min-width: 1000px;
    height:2500px;
    top:60px;
    position:absolute;
}