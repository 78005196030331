.list-view-container {
  .ludis_table_container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .ludis_table {
    width: 100%;
    background-color: #FFFFFF;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #E7E7ED;
    border-radius: 8px;
    table-layout: fixed;

    .ludis_table_header_cell {
      font-size: 14px;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      padding: 16px;
      color: #272833;
      background-color: #f7fafc;
      border-bottom: 1px solid #E7E7ED;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }

      &:hover::after {
        content: attr(title);
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px;
        background-color: #333;
        color: white;
        border-radius: 4px;
        font-size: 12px;
        white-space: normal;
        z-index: 1000;
        max-width: 200px;
        word-wrap: break-word;
      }
    }

    .ludis_table_cell {
      font-size: 14px;
      font-family: "Lato", sans-serif;
      font-weight: 400;
      padding: 16px;
      color: #646372;
      border-bottom: 1px solid #E7E7ED;
      text-align: left;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;

      &:hover::after {
        content: attr(title);
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px;
        background-color: #333;
        color: white;
        border-radius: 4px;
        font-size: 12px;
        white-space: normal;
        z-index: 1000;
        max-width: 200px;
        word-wrap: break-word;
      }

      .btn_transperent {
        background: transparent;
        border: none;
        padding: 0;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        .show__tool {
          display: flex;
          align-items: center;

          i {
            font-size: 16px;
            color: lightgray;

            &.show_pin_app {
              color: #ffd700;
            }
          }

          .show_pin_rep {
            margin-left: 4px;
            font-size: 12px;
            display: none;
          }
        }

        &:hover {
          .show_pin_rep {
            display: inline;
          }
        }
      }
    }

    .ludis_table_row {
      &:hover {
        background-color: #f7fafc;
      }

      &:last-child {
        .ludis_table_cell {
          border-bottom: none;

          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
}