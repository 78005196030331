/* TODO: Clean up file, and add comments for components */

$border-rad5: 3px;
$cont-height: 69.5%;

/* ====================================
 Insights - index.js
==================================== */

.mainCreationComponent {
	position: absolute;
	display: grid;
  	grid-template-columns: 140px calc(96% - 140px);
	column-gap: 1%;
	row-gap: 1%;
	top: 10px;
	left: 144px;
	height: 590px;
	width: 90%;
}

.rc-steps {
	top: 35px;
}

.navButtons {
	display: flex;
	flex-direction: row;
	gap: 10px;
	right: 18%;
	top: 625px;
	position: absolute;
}

.backButton {
	display: flex;
	/* CTA shadow */
	filter: drop-shadow(2px 8px 15px rgba(107, 113, 251, 0.25));
}

.nextButton {
	/* Button */

	/* Auto layout */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	gap: 6px;

	position: absolute;
	width: 180px;
	height: 50px;
	pointer-events: none;

	/* Disabled */
	background: #cdcdcd;
	border-radius: 8px;
}

.nextButtonSelected {
	/* Button */

	/* Auto layout */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	gap: 6px;

	position: absolute;
	width: 180px;
	height: 50px;
}



/* ====================================
 StepBar.js
==================================== */
/* Styling for StepBar Container */
.stepBarContainer {
	box-sizing: border-box;
	min-width: 140px;
	border-color: black;
	background: #ffffff;
	border-radius: 10px;
	display: inline-table;
	vertical-align: to;
}

/* Styling for StepBar Component */
.stepBar {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	position: absolute;
	width: 135px;
	height: 526px;
	left: 10px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* ====================================
 AppCreation.js
==================================== */

/* Main styling for the AppCreation container componenent */
.appCreationContainer {
	background: #ffffff;
	box-shadow: -10px -10px 30px rgba(255, 255, 255, 0.1),
		10px 10px 40px rgba(180, 180, 224, 0.2);
	border-radius: 10px;
}

/* App Info Panel Block */
.appInputPanel {
	position: relative;
	display: flex;
	flex-direction: column;
	top: 2%;
	left: 2%;
	width: 96%;
	height: 96%;
	gap: 2%;
	overflow-y: scroll;
    overflow-x: hidden;
	padding: 5px;
}

.stepTitleText {
	/* Set Step Title Font */
	font-family: 'Lato';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
	/* Accent color 1 */
	color: #338BFF;
}

.inputPanelStyle {
	box-sizing: border-box;
	width:100%;
	padding:10px;
	margin:10px 0; // add top and bottom margin
	border: 2px solid #eee;
	box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
	border-radius:10px;
}

/* ====================================
 TemplatePicker.js
==================================== */

.imageSelectionContainer {
	display: grid;
  	grid-template-columns: repeat(auto-fill, 320px); //1fr 1fr 1fr;
  	gap: 20px;
}

/* ====================================
 TemplateImage.js
==================================== */
.activeImage {
	border: $border-rad5 solid #338BFF;
	border-radius: $border-rad5;
	-moz-border-radius: $border-rad5;
    -webkit-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
}

.notActiveImage {
	border: $border-rad5 solid #fff;
	border-radius: $border-rad5;
	-moz-border-radius: $border-rad5;
    -webkit-border-radius: $border-rad5;
    -khtml-border-radius: $border-rad5;
}

.templateImage {
	float: left;
	min-width: 320px;
    min-height: 240px;
    background: #ffffff;
	border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
    overflow: hidden;

	figure {
		margin: 0px 0 0 0rem;
		width: 100%;
		height: 100%;
	}
}

.templateImageId {
	margin-bottom: 0;
}

.templateImageIa {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: $border-rad5;
		-moz-border-radius: $border-rad5;
		-khtml-border-radius: $border-rad5;
	}
}

.templatePics {
	display: flex;
	justify-content: center;
	object-fit: fill;
	height: 200px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		min-height: 200px;
		max-width: 825px;
	}
}

.templatePicsf {
	height: auto;
}

.notActiveImage .textOverlay {
	background-color: #fff;
	text-align: center;

	h4 {
		color:  #8384A1;
		text-transform: uppercase;
		line-height: 26px;
	}

	p {
		color: #8384A1;
		text-transform: uppercase;
		font-size: 14px;
	}
}

.activeImage .textOverlay {
	background-color: #338BFF;
	text-align: center;

	h4 {
		color:  rgb(255, 255, 255);
		text-transform: uppercase;
		line-height: 26px;
	}

	p {
		color: rgba(255, 255, 255, 0.7);
		text-transform: uppercase;
		font-size: 14px;
	}
}

.textOverlay {
	.appInfoWrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.appTitle {
			display: flex;
			flex-direction: column;
			width: 100%;
			word-wrap: break-word;
		}
	}
}

.data-upload-and-reqs {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
}

.data-uploaded-reselect {
	display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 10px;
}

.data-uploaded-text {
	display: flex;
	padding: 18px 232px 18px 18px;
	align-items: center;
	gap: 14px;

	border-radius: 8px;
	border: 1px solid #338BFF;
	background: #F8FBFF;
}

.data-upload {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	width: 50%;
	height: 165px;

	border-radius: 10px;
	border: 1px dashed var(--accent-color-1, #338BFF);
	background: #F8FBFF;
}

.data-upload-background {
	display: flex;
	align-items: flex-start;
	gap: 56px;
	width: 75%;
	overflow: hidden;
}

.data-uploaded {
	display: flex;
	flex-direction: column;
	gap: 5%;
    align-items: flex-start;
	color: #4EBE59;

	/* text-base 16px */
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
}

.data-upload-button {
	display: flex;
	min-width: 96px;
	padding: 8px 12px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6px;

	border-radius: 8px;
	border: 1px solid var(--accent-color-1, #338BFF);
	background: #FFF;
}

.data-upload-ludis-image {
	width: 32px;
	height: 32px;
}

.data-upload-modal {
	height: 500px;
}

.data-reqs {
	width: 100%;
	height: 320px;
	overflow: hidden;

	border-radius: 10px;
	background: #FAFBFF;
}

.data-reqs-title {
	width: 100%;
	padding: 10px;
	overflow: hidden;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
}

.data-reqs-title-text {
	color: #338BFF;
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 22.4px */
}

.data-teqs-title-text-sub {
	color: var(--gray-1, #8384A1);

	/* text-sm 14px */
	font-family: Lato;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
}

.data-reqs-content-label {
	display: inline-flex;
	align-items: center;
	gap: 5%;
	margin-left: 20px;
	width: 97%;
}

.data-reqs-content-label-column {
	width: 21%;
	height: 55px;
	overflow: hidden;
}

.data-reqs-content {
	display: inline-flex;
	align-items: flex-start;
	gap: 5%;
	margin-left: 20px;
	width: 94%;
}

.data-reqs-column-outline {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
}

.data-reqs-column {
	display: flex;
	width: 21%;
	flex-direction: column;
	align-items: flex-start;
	flex-shrink: 0;

	border-radius: 10px;

	/* shadow 1 */
	box-shadow: 15px 15px 60px 0px rgba(197, 197, 219, 0.25);
}

.data-reqs-column-text {
	display: flex;
	padding: 8px 0px;
	height: 40px;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	overflow: hidden;
	/* table-item/border-bottom */
	box-shadow: 0px -1px 0px 0px #E6E7E9 inset;
}

.data-reqs-sample-data {
	display: flex;
	width: 155px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 4px;
}

.modal-dialog {
	text-align: left;
}

.modal-title {
	text-align: center;
}