.axisDisplay {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #F9FAFE;
    gap: 20px;
    /* Stroke Color */
    border-right: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;

    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }

.clusterNumber {
    background: #F9FAFE;
    border-bottom: 1px solid #E2E2E2;
    border-radius: 0px;

    grid-column: 2 / 2;
    grid-row: 1 / 1;
}

.clusterPlot {
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    border-top: 1px solid #E2E2E2;

    padding: 0px;
    width: 93%;
    height: 470px;
    margin-left: 1%;
    top: 615px;
    grid-gap: 2.5%;
}

.clusterPlotStyle {
    box-sizing: border-box;

    width: 80%;
    height: 470px;
    border-radius: 0px 0px 10px 10px;
}

.clusterPlot2 {
    background: #FFFFFF;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding-left: 1%;
}

.graph,
.js-plotly-plot,
.plot-container {
    width:100%;
    overflow: hidden;
}

.dataPoint {
    position: absolute;
    width: 22%;
    height: 470px;
    left: 1132px;
    top: 1048px;

    background: #FFFFFF;
}
.axisDisplayTextCluster {
    margin-left: 1%;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #646372;
}
.axisDisplayText {
    width: 93%;
    margin-left: 1%;
    text-indent: 1%;
    background: #F4F5F9;
    /* Stroke Color */

    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #646372;
}

.dropdownsCustom {
    padding-left: 3%;
    display: flex;
    flex-direction: row;
    height: 75%;
    gap:5%;
}

.clusterNumberHeader {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #646372;
    margin: 10px;
}

.clusterNumberSlider {
    width: 33%;
    margin: 25%;
}

.customGraph {
    background: #FFFFFF;
    height: 470px;
    border-right: 1px solid #E2E2E2;
}

.dropdownRow {
    display: flex;
    flex-direction: column;
    gap: 5%;
    margin-top: 30px;
    min-width: 20%;
}

.dropdownRowSAD {
    display: flex;
    flex-direction: column;
    gap: 5%;
    margin-top: 30px;
    min-width: 100%;
}

.dropdownText {
    width: 46px;
    height: 22px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 140%;
    color: #272845;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.dropdownSelect {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;

    flex: none;
    flex-grow: 0;
}

.datatableStyle {
    width: 93%;
    height: 90%;
    padding-left: 1%;
    top: 1120px;
}

.toggle__div {
    top:5px
}

.gridContainer {
    display: inline-grid;
    grid-template-columns:  75% 25% ;
    margin-left: 1%;
    width: 93%;
    grid-template-rows: 300px 450px;
    top: 350px;
    background: #fff;
}

.dataInfoContainer {
    border-radius: 10px;
    background: #F8F9FD;
    overflow: auto;
    margin: 5px;
    padding-top: 10px;
    min-height: 200px;
}


.axisDisplayX {
    height: 60%;
    background: #FFFFFF;
    box-shadow: -10px -10px 30px rgba(255, 255, 255, 0.1), 10px 10px 40px rgba(180, 180, 224, 0.2);
    border-radius: 8px;

}

.axisDisplayXItems {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
}

.axisDisplayXLabelBox {
    color: var(--Gray-2, #646372);
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.axisDisplayXNumFont {
    color: var(--Text-color, #272845);
    text-align: center;

    /* text-lg 20px */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
}

.axisDisplayXSubLabelFont {
    color: var(--Gray-1, #8B8CA9);
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
}