

.lds_right_box_wrapper .lds_cp_box .lds_cp_box_heading_second .lds_cp_tp_box.active {
  border: 2px solid $primary-color;
  transition: all 0.5s;
}

.radio-box {
  input {
    position: absolute;
  }

  label {
    position: relative;
  }
}

.widget-dropdown {
  width: 150px !important;
}

.lds_po_filter_dropdown {
  width: 100px;

  .custom-select-picker {
    z-index: 999;

    .css-1uccc91-singleValue .font-size-normal i {
      display: none;
    }

    .css-26l3qy-menu {
      width: 150px;
      font-size: 14px;

      .font-size-normal i:before {
        font-size: 14px !important;
      }

      .css-4ljt47-MenuList div {
        cursor: pointer !important;
      }

      .css-9gakcf-option,
      .css-1n7v3ny-option,
      .css-4ljt47-MenuList div:hover,
      .css-4ljt47-MenuList div:active,
      .css-4ljt47-MenuList div:visited {
        color: $primary-color;
        background: transparent;
        cursor: pointer !important;
      }
    }

    .css-1pahdxg-control {
      border: none;
      padding: 0;
      box-shadow: none;
      background: transparent;
    }

    .css-1wa3eu0-placeholder,
    .css-tlfecz-indicatorContainer {
      color: #000;
      font-weight: 400;
      font-size: 14px;
    }

    .css-yk16xz-control {
      background: none;
      border: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .cursor-pointer {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.bg-none {
  background: none;
}

.sidebar_active_link {
  .navi_img1 {
    opacity: 0 !important;
    visibility: hidden !important;
  }

  .navi_img2 {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.lds_search_box {
  display: block !important;
}

.lds_search_box.show.down {
  overflow-y: hidden;
  max-height: 100px;
  transition: all 0.5s ease-in-out;
}

.lds_search_box.show.up {
  overflow-y: hidden;
  max-height: 0px;
  transition: all 0.5s ease-in-out;
}

.select__input {
  .css-1pahdxg-control:hover {
    border-color: $primary-color  !important;
    box-shadow: none !important;
  }

  .css-1pahdxg-control {
    border-color: $primary-color  !important;
    box-shadow: none !important;
  }

  .css-9gakcf-option {
    background: $primary-color  !important;
  }

  .css-b8ldur-Input input {
    height: 41px !important;
  }
}

.modale.opened {
  position: fixed;
  width: 100%;
  z-index: 999;

  .modal-dialog {
    top: 25%;
  }
}

//confirm model style
.modale:before {
  content: "";
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.opened:before {
  display: block;
}

.opened .modal-dialog {
  top: 33%;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

.modal-dialog {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  text-align: center;
  //position: fixed;
  left: 0;
  right: 0;
  margin: 0px auto;
  z-index: 11;
  width: 93%;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;

  .modal-body {
    p {
      font-weight: 400;

      span {
        font-weight: 400;
      }
    }

    //dev changes
    a,
    button {
      float: left;
    }

    .btn_pop {
      margin-left: 10px;
    }
  }
}

//
.border-round {
  border-radius: 50%;
}

.logout-alert-modal {
  .swal-button--confirm {
    background: $primary-color  !important;
  }
}

.lds_pl_model {
  .modal {
    top: 23%;
  }

  .modal-header {
    h4 {
      font-size: $-h4;
      color: $black;
      font-family:"Exo 2";
      font-weight: 700;
      text-transform: capitalize;
      line-height: 28px;
    }
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 20px;

    form {
      input {
        width: 100%;
        height: 55px;
        font-size: 18px;
        padding-left: 20px;
        margin-top: 20px;
        text-transform: capitalize;
        border-radius: $border-rad5;
        -moz-border-radius: $border-rad5;
        -khtml-border-radius: $border-rad5;
        border: 1px solid #dddddd;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;

        &:hover,
        &:focus {
          -webkit-transition: all 0.5s;
          -o-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -moz-transition: all 0.5s;
          transition: all 0.5s;
          border: 1px solid $primary-color;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
}

.lds_po_tabs_main_box .tab-content {
  float: left;
  width: 100%;
}

.lds_right_po_box_wrapper .lds_po_tabs_main_box {
  float: left;
  width: 100%;
}

.profile img {
  object-fit: cover;
}

#gitModal .modal-header {
  img {
    padding-left: 10px;
    height: 18px;
    margin-top: -3px;
  }
}

#gitModal .modal-body {
  input {
    text-transform: none !important;
  }

  h4 {
    text-align: left;
    padding-top: 10px;
    margin-bottom: -10px;
  }
}

.swal-title {
  font-size: large;
  text-align: left;
}

.swal-title:first-child {
  margin-top: 0px;
}

.swal-modal {
  padding: 15px;
  width: 425px;
  border-radius: 15px;
}

.swal-text {
  margin: 10px 0px;
  padding-bottom: 50px;
}

.swal-footer {
  button {
    margin-right: 10px;
  }
}

.swal-footer {
  width: 100%;
  margin: auto;
  text-align: center;
}

.word-break {
  word-break: break-all;
}

.lds_account_back_main_wrapper {
  background-image: url("../images/thumbnails/login_bg.jpg");
  background-size: cover;
  background-position: center 0;
  min-height: 100vh;
  padding-bottom: 40px;
}

.notebook-open-wrapper {
  margin-top: 0 !important;
  padding-top: 10px !important;
  margin-right: 50px;

  img {
    height: 50px;
  }

  .btn {
    margin-right: 10px;
  }

  .iframe_title_cover {
    background-color: none;
    height: 40px;
    position: relative;
    top: 57px;
    border-width: medium;
    margin-left: 1px;
    margin-right: 1px;
  }

  .iframe_logout_cover {
    background-color: #ffffff;
    height: 40px;
    position: relative;
    top: 17px;
    border-width: medium;
    margin-left: 63%;
    margin-right: 1px;
  }

  .iframe_options_cover {
    background-color: #ffffff;
    height: 34px;
    position: relative;
    top: 19px;
    border-width: medium;
    margin-left: 1px;
    margin-right: 1px;
  }

  @media only screen and (max-width: 680px) {
    .iframe_options_cover {
      background-color: #ffffff;
      top: 49px;
    }

    .iframe_logout_cover {
      margin-left: 57%;
    }

    @media only screen and (max-width: 572px) {
      .iframe_options_cover {
        background-color: #ffffff;
        top: 19px;
      }
    }
  }

  .iframe-container {
    iframe {
      width: 100%;
      height: 100vh;
      border: solid 1px #eee;
      margin-top: -60px;
    }
  }

  .iframe-container2 {
    iframe {
      width: 100%;
      height: 100vh;
      border: solid 1px #eee;
    }
  }
}

.iframe-container3 {
  iframe {
    width: 100%;
    height: 100vh;
    border: solid 1px #eee;
    margin-top: 20px;
  }
}

.btn:disabled {
  pointer-events: none;
  border-color: #cdcdcd;
  background-color: #cdcdcd;
}

.btn_small:disabled {
  pointer-events: none;
  border-color: #cdcdcd;
  background-color: #cdcdcd;
}

.desc.custom-select-picker svg {
  transform: rotate(180deg);
}

.default-delete-btn {
  color: #a6a6a6;
  font-size: 20px;
}

.disabled {
  cursor: default;
  pointer-events: none;
  background-color: #ebebe4 !important;

  .css-yk16xz-control {
    background-color: #ebebe4 !important;
  }
}

//insights share app modal

#share_modal {
  margin-top: 150px;
}

#edit_modal {
  margin-top: 150px;
  max-width: 450px;

  .modal-body {
    width: 100%;
    padding: 0;
  }
}


@media only screen and (max-width: 991px) {
  .side_lds_main_menu_wrapper {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .side_lds_main_menu_wrapper {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
  .hamburger-menu {
    display: none;
  }
}
.hamburger-menu {
  .dropdown-toggle::after {
    display: none;
  }
  #dropdown_menu_icn {
    border-radius: 3px;
    color: #ffffff;
  }
  .btn {
    margin: 15px;
    padding: 10px;

  }
  .show>.btn-primary.dropdown-toggle {
    color: $primary-color;
    background-color: $primary-color;
  }
  .dropdown-item {
    color: $primary-color-light;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    img {
      height: 1em;
      width: 1em;
      margin-right: 10px;
      margin-left: 3px;
    }
    svg {
      margin-right: 5px;
    }
  }
}







.side_lds_main_menu_wrapper::-webkit-scrollbar {
  width: 7px;
}

.side_lds_main_menu_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.side_lds_main_menu_wrapper::-webkit-scrollbar-thumb {
  background: rgba(158, 180, 202, 0.36);
}

.customSelect:active,
.customSelect:focus {
  border: none !important;
}

.custom-input-box {
  border: solid 1px #eee;
  border-radius: 5px;
  width: fit-content;
}

.model__body {
  max-height: 350px;
  overflow-y: auto;
}

.active_label {
  opacity: 0.8;
  background: #eee;
}