//colors
$black: #000000;
$white: #ffffff;

$primary-color: #6B71FB;
$primary-color-light: #5E6EC3;
$muted-primary: #8892C7;
$secondary-color: #ff5f49;
$body-color: #797979;

$gray-light: #CDCDCD;
$gray-medium: #8384A1;
$gray-dark: #4D4F77;

$muted-gray: #8B8CA9;
$primary-blue: #4C5BAB