@media (max-width: 1530px) {
  .lds_en_main_wrapper .lds_en_body .lds_en_navigation .lds_en_left_menu {
    width: 100%;
    text-align: center;

    ul {
      float: none;
      display: inline-block;
    }
  }

  .lds_en_main_wrapper .lds_en_body .lds_en_navigation .lds_en_right_menu {
    width: 100%;
    text-align: center;

    ul {
      float: none;
      display: inline-block;
    }
  }
}

@media (max-width: 1199px) {
  .lds_ap_heading {
    display: block !important;
    top: 0 !important;
    padding-bottom: 30px !important;
  }

  .pr__BX_1,
  .pr__BX_2 {
    padding: 40px 40px 40px 120px;
  }

  .lds_en_main_wrapper .lds_en_sidebar {
    right: -390px;
  }

  body.active .lds_en_sidebar {
    right: 0;
  }

  .lds_en_main_wrapper .lds_en_body {
    width: 100%;
  }

  .lds_en_main_wrapper .lds_en_body .lds_en_navigation {
    width: 100%;
  }
}

@media (max-height: 650px) {
  .lds_main_menu_wrapper ul li a {
    padding-top: 5px;
  }

  .lds_main_menu_wrapper ul li .button {
    padding-top: 5px;
  }
}

@media (max-width: 991px) {
  .lds_main_menu_wrapper {
    width: 220px;
    height: 100%;
    left: -220px;
    top: 0;
    -webkit-box-shadow: 5px 0px 9px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 5px 0px 9px 0 rgba(0, 0, 0, 0.06);

    ul {
      li {
        a {
          span {
            visibility: visible;
            opacity: 1;
            padding-left: 30px;
          }
        }
      }
    }
  }

  .lds_toggle {
    float: right;
    cursor: pointer;
    padding-right: 15px;
  }

  body.active .lds_main_menu_wrapper {
    left: 0 !important;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
  }

  .lds_right_box_wrapper {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;

    .lds_cp_heading {
      display: block;
      padding-bottom: 30px;
      padding-right: 0;
    }
  }

  .lds_main_menu_wrapper {
    ul {
      .lds_notify_list {
        ul {
          visibility: visible;
          opacity: 1;
          top: 0;
          height: 100%;
          left: -370px;

          li {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  body.active-second .lds_main_menu_wrapper ul .lds_notify_list ul {
    left: 0;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
  }

  .container {
    max-width: 100%;
    padding: 0;
  }

  .home__Intro {
    margin-left: 100px;
  }

  .tab-content {
    padding: 0 15px;
  }

  .lds_right_po_box_wrapper .lds_po_tabs_main_box .lds_po_search .lds_search_box {
    right: auto;
    left: 15px;
  }

  .lds_po_tabs {
    padding-bottom: 20px;
  }

  .pr__BX_1,
  .pr__BX_2 {
    padding: 40px;
  }

  .lds_project_right_top_box_wrapper,
  .lds_project_bottom_box_wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .lds_toggle_pd {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .bg__pic {
    z-index: -5;
  }

  .lds_ddt_inner_box3 {
    margin-top: 30px;
  }

  .lds_ddt_over_box_last {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .lds_cp_tp_box2 {
    margin-top: 30px;
  }

  .lds_right_po_box_wrapper .lds_po_tabs_main_box .lds_po_search {
    float: left;
  }

  .lds_right_po_box_wrapper .lds_po_tabs_main_box .lds_po_filter_dropdown {
    float: left;
    margin-right: 0;
    margin-left: 15px;
  }

  .lds_project_right_top_box_wrapper .lds_pss_top_right ul {
    float: left;
  }

  .lds_pd_last_tabu__Sper {
    margin-bottom: 0;
  }

  .pr__BX_1 {
    padding: 30px !important;
    margin-bottom: 25px;
  }

  .detasets__block {
    margin-top: 0;
  }

  .home__multi .pr__bottom_Top .see__an {
    margin-top: 3px;
  }

  .market__lus .pr__bottom_Top h2,
  .home__multi .pr__bottom_Top h2 {
    font-size: 24px;
  }

  .market__lus .pr__bottom_Top h4 {
    margin-top: 20px;
  }

  .home__multi {
    padding-bottom: 15px;
    margin-bottom: 30px;
  }

  .insights__link {
    margin: 0 auto;
  }

  .home__graph {
    padding-bottom: 20px;
  }

  .home__Title {
    font-size: 40px;
    line-height: 54px;
  }

  .home__Intro {
    margin-bottom: 30px;
  }

  .lds_il_right_btn ul {
    float: left;
    margin-top: 30px;
  }

  .lds_il_right_btn ul li:first-child {
    display: none;
  }

  .lds_ddt_inner_box2 {
    margin-top: 30px;
  }

  .home__Title br {
    display: none;
  }

  .home__Intro p {
    max-width: 100%;
  }

  .lds_en_main_wrapper .lds_en_top_header .lds_en_top_left {
    width: 100%;
  }

  .lds_en_main_wrapper .lds_en_top_header .lds_en_top_right {
    width: 100%;
  }

  .lds_en_main_wrapper .lds_en_body .lds_en_navigation {
    width: 200px;
    height: 100%;
    top: 0;
    display: none;
  }

  .lds_en_main_wrapper .lds_en_top_header {
    position: unset;
  }

  .lds_en_main_wrapper .lds_en_body {
    margin-top: 0;
  }

  .lds_en_main_wrapper .lds_en_body .lds_en_dash_body {
    padding-top: 0;
  }

  .lds_en_main_wrapper .lds_en_sidebar {
    height: 100%;
    top: 0;
  }

  .lds_en_main_wrapper .lds_en_sidebar {
    width: 290px;
    right: -290px;
  }

  .lds_en_main_wrapper .lds_en_top_header .lds_en_top_right ul {
    float: left;
    margin-top: 20px;
  }
}

@media (max-width: 580px) {
  .lds_login_main_wrapper {
    padding: 0 15px;
    padding-bottom: 40px;

    .lds_login_box {
      width: 100%;
      padding: 40px 15px;

      .lds_login_heading {
        h1 {
          font-size: 34px;
        }
      }

      .lds_login_form {
        .lds_login_form_btn {
          a {
            display: block;
            padding-left: 0;
            padding-top: 10px;
          }
        }
      }
    }

    .lds_login_logo {
      padding: 40px 0;
    }
  }

  .hl_link_page_main {
    padding-left: 15px;
    padding-right: 15px;

    .lds_login_logo {
      padding: 30px 15px;
      padding-left: 0;
    }
  }

  .lds_right_po_box_wrapper .lds_po_cp_btn a {
    float: left;
    margin-top: 20px;
  }

  .ml_icn {
    text-align: left;
  }

  .sper__right {
    width: 100%;
    padding-top: 20px;
  }

  .pr__BX_2 {
    margin-top: 30px;
  }

  .lds_pd_heading_btn h4 {
    width: 100%;
    padding-top: 0;
  }

  .lds_pd_heading_btn a {
    float: left;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .home__Title {
    font-size: 34px;
    line-height: 44px;
  }

  .lds_en_main_wrapper .lds_en_top_header .lds_en_top_right ul li:first-child {
    width: 100%;
    margin-bottom: 20px;
  }

  .lds_en_main_wrapper .lds_en_top_header .lds_en_top_right ul li:nth-child(2) {
    margin-left: 0;
  }

}

@media (max-width: 380px) {
  .lds_right_po_box_wrapper .lds_po_tabs_main_box .lds_po_filter_dropdown {
    margin-left: 5px;
  }

  .figure_info {
    font-size: 13px;
  }

  .figure_datasets {
    margin: 7px 3px 0 0;
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 320px) {}