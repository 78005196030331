//toaster styles

@mixin toast-style($border-color, $title-color) {
  background-color: #fff;
  border-left: 10px solid $border-color;

  .toast-title {
    color: $title-color
  }
}

#toast-container {
  .toast {
    opacity: 1;
    width: 350px;
    padding: 15px 15px 15px 20px;
  }

  .toast-success {
    @include toast-style($primary-color, #51A351);
  }

  .toast-error {
    @include toast-style(#BD362F, #BD362F);
  }

  .toast-info {
    @include toast-style(#2F96B4, #2F96B4);
  }

  .toast-message {
    word-wrap: break-word;
    color: #4D4F5C;

    a,
    label {
      color: #BD362F;
    }

    a:hover {
      color: #CCCCCC;
      text-decoration: none;
    }
  }

  .toast-close-button {
    color: #777777;
    text-shadow: 0 1px 0 #777777;
  }
}

//toaster styles