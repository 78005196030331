/*body {*/
  /*margin: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
    /*monospace;*/
/*}*/
.srv-validation-message{
	color: red;
 font-style: normal;
 
 font-variant: normal;
 font-weight: normal;
 font-size: 14px;
 font-family: Lato, sans-serif;

}