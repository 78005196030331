.progress-loader {
  #overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f8f9fa42;
    z-index: 999999;
  }

  .ring {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background: transparent;
    border: 3px solid rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    font-family: sans-serif;
    font-size: 12px;
    color: $primary-color;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 10px $primary-color;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
    z-index: 999999;

    span {
      display: block;
      position: absolute;
      top: calc(50% - 2px);
      left: 50%;
      width: 50%;
      height: 4px;
      background: transparent;
      transform-origin: left;
      animation: animate 1s linear infinite;

      &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $primary-color;
        top: -5px;
        right: -3px;
        box-shadow: 0 0 20px $primary-color;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-top: 3px solid $primary-color;
      border-right: 3px solid $primary-color;
      border-radius: 50%;
      animation: animateCircle 1s linear infinite;
    }
  }

  @keyframes animateCircle {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }

    100% {
      transform: rotate(405deg);
    }
  }
}