.container {
    width: 100%;
    height: 80vh;
    position: relative;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
  }
  
  .container svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  
  
  